import {
    Configuration,
    ServiceConfiguration,
    ConfigurationOwner,
} from 'src/models/administration/configurationData.model';
import { DataTypeConfig } from 'src/models/dataType.enum';

export enum APIConfigurationLevel {
    Default = 'Default',
    Agency = 'Agency',
    Client = 'Client',
    Device = 'Device',
    Officer = 'Officer',
}

export interface APIServiceConfiguration {
    name: string;
    captureInterval: number;
    syncInterval: number;
    syncRate: number;
    enabled: boolean;
}

export interface APIBlockedAppConfiguration {
    name: string;
    packageName?: string;
    path?: string;
    programName:string;
}

export interface APIConfigurationResponse {
    id: string;
    configurationLevel: APIConfigurationLevel;
    configuredId: string;
    services: APIServiceConfiguration[],
    blockedApps: APIBlockedAppConfiguration[],
    blockedUrls: string[],
    blockUninstall: boolean,
    blockAccessSetting: boolean,
    syncInterval: number,
    captureInterval: number,
    disableConfig: boolean,
    isLogCapture: boolean,
    isUninstallNotification: boolean,
    permissionScreenAccess?:boolean,
}

function APIConfToLocalConf(conf: APIServiceConfiguration): ServiceConfiguration
{
    const {
        name,
        ...confFields
    } = conf;

    return {
        app: name,
        ...confFields,
    };
}

export function transformAPIConfigurationLevel(
    configurationLevel: APIConfigurationLevel
): ConfigurationOwner
{
    switch (configurationLevel)
    {
    case APIConfigurationLevel.Agency:
        return ConfigurationOwner.Agency;
    case APIConfigurationLevel.Client:
        return ConfigurationOwner.Client;
    case APIConfigurationLevel.Device:
        return ConfigurationOwner.Device;
    case APIConfigurationLevel.Default:
        return ConfigurationOwner.Default;
    case APIConfigurationLevel.Officer:
        return ConfigurationOwner.Officer;
    }
}

export function transformConfigurationOwner(
    configurationLevel: ConfigurationOwner
): APIConfigurationLevel
{
    switch (configurationLevel)
    {
    case ConfigurationOwner.Agency:
        return APIConfigurationLevel.Agency;
    case ConfigurationOwner.Client:
        return APIConfigurationLevel.Client;
    case ConfigurationOwner.Device:
        return APIConfigurationLevel.Device;
    case ConfigurationOwner.Default:
        return APIConfigurationLevel.Default;
    case ConfigurationOwner.Officer:
        return APIConfigurationLevel.Officer;
    }
}

export function readAPIConfigurationResponse(
    configuration: APIConfigurationResponse
): Configuration
{
    return {
        id: configuration.id,
        ownerType: transformAPIConfigurationLevel(
            configuration.configurationLevel
        ),
        ownerId: configuration.configuredId,
        services: configuration.services.map(APIConfToLocalConf),
        blockedApps: configuration.blockedApps,
        blockedUrls: configuration.blockedUrls,
        blockUninstall: configuration.blockUninstall,
        blockAccessSetting: configuration.blockAccessSetting,
        disableConfig: configuration.disableConfig,
        isLogCapture: configuration.isLogCapture,
        isUninstallNotification: configuration.isUninstallNotification,
        permissionScreenAccess:configuration?.permissionScreenAccess
    };
}

export function parseConfigurationService(service: ServiceConfiguration): APIServiceConfiguration[]
{
    const {
        app,
        ...restServiceProps
    } = service;
    if (app === DataTypeConfig.SEARCHES_WEBSITES)
    {

        return [{
            name: DataTypeConfig.SEARCHES,
            ...restServiceProps,
        }, {
            name: DataTypeConfig.WEBSITES,
            ...restServiceProps,
        }];
    }
    else
    {
        return [{
            name: app,
            ...restServiceProps,
        }];
    }
}

export function parseConfiguration(configuration: Configuration): APIConfigurationResponse
{
    return {
        id: configuration.id,
        configurationLevel: transformConfigurationOwner(configuration.ownerType),
        configuredId: configuration.ownerId,
        services: configuration.services.flatMap(parseConfigurationService),
        blockedApps: configuration.blockedApps,
        blockedUrls: configuration.blockedUrls,
        syncInterval: 30,
        captureInterval: 30,
        blockUninstall: configuration.blockUninstall,
        blockAccessSetting: configuration.blockAccessSetting,
        disableConfig: configuration.disableConfig,
        isLogCapture: configuration.isLogCapture,
        isUninstallNotification: configuration.isUninstallNotification,
        permissionScreenAccess:configuration?.permissionScreenAccess
    };
}
