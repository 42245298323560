import { PaginatedContent } from 'src/models/paginatedResult.model';
import { readAPIPaginatedResponse } from '../data-transfer-objects/pagination';
import instance from '../instance';
import {
    DeviceDetails,
    DeviceDetailsPaginatedParams,
    DeviceDetailsParams,
    DevicesCountDetails,
} from 'src/models/administration/deviceDetailsData.model';
import {
    readAPIDeviceCountDetailsResponse,
    readAPIDeviceDetailsResponse,
    transformDeviceDetailsPaginatedParams,
    transformDeviceDetailsParams,
} from '../data-transfer-objects/administration/deviceDetails';

export async function getDevicesDetails(
    params: DeviceDetailsPaginatedParams
): Promise<PaginatedContent<DeviceDetails>>
{
    const response = await instance.get('device-detail/list', {
        params: transformDeviceDetailsPaginatedParams(params),
    });

    return readAPIPaginatedResponse(response.data, readAPIDeviceDetailsResponse);
}

export async function getDevicesCountDetails(
    params: DeviceDetailsParams
): Promise<DevicesCountDetails[]>
{
    const response = await instance.get('device-detail/deviceCount', {
        params: transformDeviceDetailsParams(params),
    });
    return readAPIDeviceCountDetailsResponse(response.data);
}

