import {
    SuggestedAlertWordsList,
    ClientAWLCreationParams,
    AWLRetrieveParams,
    AWLCreationParams,
    AWLUpdateParams,
    CustomAWLCreationParams,
    CustomAlertWordsList,
    AgencyAWLConfiguration,
    AgencyAWLConfigurationUpdateParams,
    CustomAWLsRetrieveParams,
    CustomAWLUpdateParams,
    ClientAWLUpdateParams,
    AWLClientParams,
    ClientAWLToggleUpdateParams,
    ClientAlertWordsToggleList,
    OfficerAWLUpdateParams,
    AWLOfficerParams,
    OfficerAlertWordsList,
    OfficerAlertWordsToggleList,
    OfficerAWLToggleUpdateParams,
    OfficerAWLCreationParams,
    AWLDeleteParams,
} from 'src/models/administration/alertWordListData.model';
import {
    PaginatedContent,
} from 'src/models/paginatedResult.model';
import {
    readAPIAWLResponse,
    transformAWLCreationParams,
    transformClientAWLCreationParams,
    transformAWLRetrieveParams,
    transformCustomAWLCreationParams,
    readAPICustomAWLResponse,
    transformAgencyAWLConfigurationParams,
    readAPIAgencyConfiguration,
    transformCustomAWLsRetrieveParams,
    transformClientAWLParams,
    transformClientAWLToggleCreationParams,
    readAPIClientAWLToggleResponse,
    transformOfficerAWLParams,
    readAPIOfficerAWLResponse,
    transformOfficerAWLCreationParams,
    readAPIOfficerAWLToggleResponse,
    transformOfficerAWLToggleCreationParams,
    transformAWLDeletionParams,
} from '../data-transfer-objects/administration/alertWordList';
import {
    readAPIPaginatedResponse,
} from '../data-transfer-objects/pagination';
import instance from '../instance';

export async function createSuggestedAWL(
    params: AWLCreationParams
): Promise<SuggestedAlertWordsList>
{
    const response = await instance.post(
        'alertwords/suggested',
        transformAWLCreationParams(params)
    );

    return readAPIAWLResponse(response.data);
}

export async function createClientAWL(
    params: ClientAWLCreationParams
): Promise<OfficerAlertWordsList>
{
    const response = await instance.post(
        '/alertwords/client',
        transformClientAWLCreationParams(params)
    );

    return readAPIOfficerAWLResponse(response.data);
}

export async function createOfficerAWL(
    params: OfficerAWLCreationParams
): Promise<OfficerAlertWordsList>
{
    const response = await instance.post(
        '/alertwords/officer',
        transformOfficerAWLCreationParams(params)
    );

    return readAPIOfficerAWLResponse(response.data);
}
export async function getSuggestedAWLs(
    params: AWLRetrieveParams
): Promise<PaginatedContent<SuggestedAlertWordsList>>
{
    const response = await instance.get(
        'alertwords/suggested',
        { params: transformAWLRetrieveParams(params) }
    );

    return readAPIPaginatedResponse(response.data, readAPIAWLResponse);
}

export async function getSuggestedAWLsNoPageable(): Promise<SuggestedAlertWordsList[]>
{
    const response = await instance.get(
        'alertwords/suggested/no-pageable'
    );

    return response.data.map(readAPIAWLResponse);
}

export async function updateSuggestedAWL(
    params: AWLUpdateParams
): Promise<SuggestedAlertWordsList>
{
    const response = await instance.put(
        `/alertwords/suggested/${params.id}`,
        transformAWLCreationParams(params)
    );

    return readAPIAWLResponse(response.data);
}

export async function deleteCustomAWL(params: AWLDeleteParams): Promise<void>
{
    const response = await instance.delete(
        'alertwords/custom/delete',
        {
            params: transformAWLDeletionParams(params),
        }
    );
    return response.data;
}

export async function getSuggestedAWLById(id: string): Promise<SuggestedAlertWordsList>
{
    const response = await instance.get(`alertwords/suggested/${id}`);

    return readAPIAWLResponse(response.data);
}

export async function createCustomAWL(
    params: CustomAWLCreationParams
): Promise<CustomAlertWordsList>
{
    const response = await instance.post(
        'alertwords/custom',
        transformCustomAWLCreationParams(params)
    );

    return readAPICustomAWLResponse(response.data);
}

export async function updateCustomAWL({
    id,
    ...restParams
}: CustomAWLUpdateParams): Promise<CustomAlertWordsList>
{
    const response = await instance.put(
        `/alertwords/custom/${id}`,
        transformCustomAWLCreationParams(restParams)
    );

    return readAPICustomAWLResponse(response.data);
}

export async function getCustomAWLs(
    params: CustomAWLsRetrieveParams
): Promise<PaginatedContent<CustomAlertWordsList>>
{
    const response = await instance.get(
        'alertwords/custom',
        { params: transformCustomAWLsRetrieveParams(params) }
    );

    return readAPIPaginatedResponse(response.data, readAPICustomAWLResponse);
}

export async function getCustomAWLById(id: string): Promise<CustomAlertWordsList>
{
    const response = await instance.get(`alertwords/custom/${id}`);

    return readAPICustomAWLResponse(response.data);
}

export async function getAgencyAWLConfiguration(
    agencyId?: string
): Promise<AgencyAWLConfiguration>
{
    const response = await instance.get('alertwords/agency', { params: { agencyId } });

    return readAPIAgencyConfiguration(response.data);
}

export async function updateAgencyAWLConfiguration(
    params: AgencyAWLConfigurationUpdateParams
): Promise<AgencyAWLConfiguration>
{
    const response = await instance.put(
        'alertwords/agency',
        transformAgencyAWLConfigurationParams(params)
    );

    return readAPIAgencyConfiguration(response.data);
}

export async function getClientAWLs(
    params: AWLClientParams
): Promise<PaginatedContent<OfficerAlertWordsList>>
{
    const response = await instance.get(
        '/alertwords/client',
        { params: transformClientAWLParams(params) }
    );

    return readAPIPaginatedResponse(response.data, readAPIOfficerAWLResponse);
}
export async function deleteClientAWL(id: string): Promise<string>
{
    await instance.delete(`/alertwords/client/${id}`);
    return id;
}
export async function getClientAWLById(id: string): Promise<OfficerAlertWordsList>
{
    const response = await instance.get(`/alertwords/client/${id}`);

    return readAPIOfficerAWLResponse(response.data);
}

export async function updateClientAWL({
    id,
    ...restParams
}: ClientAWLUpdateParams): Promise<OfficerAlertWordsList>
{
    const response = await instance.put(
        `/alertwords/client/${id}`,
        transformClientAWLCreationParams(restParams)
    );

    return readAPIOfficerAWLResponse(response.data);
}

export async function updateClientAWLToggle({
    client,
    clientId,
    disableAWL,
}: ClientAWLToggleUpdateParams): Promise<ClientAlertWordsToggleList>
{
    const response = await instance.put(
        '/alertwords/clientToggle',
        transformClientAWLToggleCreationParams({ client, disableAWL, clientId })
    );

    return readAPIClientAWLToggleResponse(response.data);

}

export async function getClientToggleAWLs(
    params: ClientAWLToggleUpdateParams
): Promise<ClientAlertWordsToggleList>
{
    const response = await instance.get(
        '/alertwords/clientToggle',
        { params: transformClientAWLToggleCreationParams(params) }
    );
    const finalresponse =readAPIClientAWLToggleResponse(response.data);
    return finalresponse;
}

export async function getOfficerAWLs(
    params: AWLOfficerParams
): Promise<PaginatedContent<OfficerAlertWordsList>>
{
    const response = await instance.get(
        '/alertwords/officer',
        { params: transformOfficerAWLParams(params) }
    );

    return readAPIPaginatedResponse(response.data, readAPIOfficerAWLResponse);
}

export async function getOfficerAWLById(id: string): Promise<OfficerAlertWordsList>
{
    const response = await instance.get(`/alertwords/officer/${id}`);

    return readAPIOfficerAWLResponse(response.data);
}

export async function updateOfficerAWL({
    id,
    ...restParams
}: OfficerAWLUpdateParams): Promise<OfficerAlertWordsList>
{
    const response = await instance.put(
        `/alertwords/officer/${id}`,
        transformOfficerAWLCreationParams(restParams)
    );
    return readAPIOfficerAWLResponse(response.data);
}
export async function updateOfficerAWLToggle({
    user,
    userId,
    disableAWL,
}: OfficerAWLToggleUpdateParams): Promise<OfficerAlertWordsToggleList>
{
    const response = await instance.put(
        '/alertwords/officerToggle',
        transformOfficerAWLToggleCreationParams({ user, disableAWL, userId })
    );

    return readAPIOfficerAWLToggleResponse(response.data);

}

export async function getOfficerToggleAWLs(
    params: OfficerAWLToggleUpdateParams
): Promise<OfficerAlertWordsToggleList>
{
    const response = await instance.get(
        '/alertwords/officerToggle',
        { params: transformOfficerAWLToggleCreationParams(params) }
    );
    const finalresponse =readAPIOfficerAWLToggleResponse(response.data);
    return finalresponse;
}
