import React, { useState, useEffect } from 'react';
import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    SxProps,
    Theme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ToggleSwitch } from 'src/components/toggle-switch/ToggleSwitch';
import { noop } from 'src/utils/common';
import { ServiceConfiguration } from 'src/models/administration/configurationData.model';
import SettingsIcon from '@mui/icons-material/Settings';
import { useConfigurationOptions } from 'src/utils/configurationOptions';

export const intervalContainerStyle = {
    width: '17%',
    flex: '0 1 auto',
    display: 'flex',
    alignItems: 'center',
};

export const labelStyle = (override: boolean): SxProps => ({
    textAlign: 'start',
    fontSize: '0.625em',
    flex: '1 0',
    textTransform: 'uppercase',
    fontWeight: 700,
    lineHeight: '1.2em',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    opacity: override ? 1 : 0.5,
});

export const rootStyle = {
    width: '100%',
    height: '1.75em',
    fontSize: '2rem',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '18px',
    color: (theme: Theme) => theme.palette.common.lightGrey,
};

export const toggleContainer = {
    display: 'flex',
    alignItems: 'center',
};

export interface ServiceConfigurationItem extends ServiceConfiguration {
    label: string
    override: boolean
}

export interface ServiceConfiguratorProps {
    selectedConfiguration: ServiceConfigurationItem
    onChange?: (item: ServiceConfigurationItem) => void
    disableConfig: boolean
}

export function DeviceServiceConfigurator({
    selectedConfiguration: initialConfiguration,
    onChange = noop,
}: Readonly<ServiceConfiguratorProps>): JSX.Element
{
    const { t } = useTranslation();
    const { intervalOptions, syncRateOptions } = useConfigurationOptions();

    const [selectedConfiguration, setSelectedConfiguration] =
        useState<ServiceConfigurationItem>(initialConfiguration);

    function onToggleChange(
        fieldname: keyof ServiceConfigurationItem
    ): (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
    {
        return (
            event: React.ChangeEvent<HTMLInputElement>,
            checked: boolean
        ): void =>
        {
            const newConf = { ...selectedConfiguration, [fieldname]: checked };
            setSelectedConfiguration(newConf);
            onChange(newConf);
        };
    }
    function onSelectionChange(
        fieldname: keyof ServiceConfigurationItem
    ): (event: SelectChangeEvent<number>, child: React.ReactNode) => void
    {
        return (
            event: SelectChangeEvent<number>,
            child: React.ReactNode
        ): void =>
        {
            const selectedValue = Number(event.target.value);
            const newConf = {
                ...selectedConfiguration,
                [fieldname]: selectedValue,
            };

            setSelectedConfiguration(newConf);
            onChange(newConf);
        };
    }

    useEffect(() =>
    {
        setSelectedConfiguration(initialConfiguration);
    }, [initialConfiguration]);

    return (
        <Stack direction="row" sx={rootStyle}>
            <SettingsIcon
                fontSize="inherit"
                sx={{
                    ml: 20,
                    opacity: selectedConfiguration.override ? 1 : 0.5,
                }}
            />
            <Box sx={labelStyle(selectedConfiguration.override)}>
                {selectedConfiguration.label}
            </Box>
            <Box sx={intervalContainerStyle}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                        {t('settings.capture-interval')}
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        size="small"
                        label={t('settings.capture-interval')}
                        value={selectedConfiguration.captureInterval}
                        onChange={onSelectionChange('captureInterval')}
                        disabled={!selectedConfiguration.override}
                    >
                        {intervalOptions.map(({ label, seconds }) => (
                            <MenuItem key={seconds} value={seconds}>
                                {label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <Box sx={intervalContainerStyle}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                        {t('settings.sync-interval')}
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        size="small"
                        label={t('settings.sync-interval')}
                        value={selectedConfiguration.syncInterval}
                        onChange={onSelectionChange('syncInterval')}
                        disabled={!selectedConfiguration.override}
                    >
                        {intervalOptions.map(({ label, seconds }) => (
                            <MenuItem key={seconds} value={seconds}>
                                {label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <Box sx={intervalContainerStyle}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                        {t('settings.sync-rate')}
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        size="small"
                        label={t('settings.sync-rate')}
                        value={selectedConfiguration.syncRate}
                        onChange={onSelectionChange('syncRate')}
                        disabled={!selectedConfiguration.override}
                    >
                        {syncRateOptions.map(({ label, limit }) => (
                            <MenuItem key={limit} value={limit}>
                                {label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <Box sx={toggleContainer}>
                <ToggleSwitch
                    sx={{ m: 1 }}
                    checked={selectedConfiguration.enabled}
                    onChange={onToggleChange('enabled')}
                    disabled={!selectedConfiguration.override}
                />
            </Box>
        </Stack>
    );
}
