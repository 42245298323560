import {
    CacheData,
    DeleteDeviceCacheParams,
    DeviceCacheDetails,
    DeviceCacheDetailsPaginatedParams,
    DeviceCacheDetailsParams,
} from 'src/models/administration/deviceCacheDetailsData.model';
import { APIPaginationParams, transformPaginationParams } from '../pagination';

export interface APIDeviceCacheDetailsPaginatedParams
    extends DeviceCacheDetailsParams, APIPaginationParams {}

export interface APIDeleteDeviceCacheParams {
    services: string[];
    deviceId: string;
}

export interface APIDeviceCacheDetailsResponse {
    deviceId: string;
    deviceAlias: string;
    agency: string;
    client: string;
    totalUnsyncedCount: number;
    cacheDetails: CacheData[];
}

export function transformDeviceCachePaginatedParams
(params: DeviceCacheDetailsPaginatedParams): APIDeviceCacheDetailsPaginatedParams
{
    const { searchValue, ...paginationParams } = params;

    return {
        searchValue,
        ...transformPaginationParams(paginationParams),
    };
}

export function transformDeleteDeviceCacheParams
(params: DeleteDeviceCacheParams): APIDeleteDeviceCacheParams
{
    const { services, deviceId } = params;
    return {
        services: services,
        deviceId: deviceId
    };
}

export function readAPIDeviceCacheDetailsResponse(data: APIDeviceCacheDetailsResponse): DeviceCacheDetails
{
    return {
        deviceId: data.deviceId,
        deviceAlias: data.deviceAlias,
        agency: data.agency,
        client: data.client,
        totalUnsyncedCount: data.totalUnsyncedCount,
        cacheDetails: data.cacheDetails
    };
}

