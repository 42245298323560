import { useTranslation } from 'react-i18next';

type IntervalOption = { label: string, seconds: number };
type SyncOption = { label: string; limit: number };

export function useConfigurationOptions(): {
    intervalOptions: { label: string, seconds: number }[]
    syncRateOptions: { label: string; limit: number }[]
} {
    const { t } = useTranslation();

    const intervalOptions: IntervalOption[] = [
        { label: '', seconds: 0 },
        { label: t('common.time.seconds', { seconds: 10 }), seconds: 10 },
        { label: t('common.time.seconds', { seconds: 20 }), seconds: 20 },
        { label: t('common.time.seconds', { seconds: 30 }), seconds: 30 },
        { label: t('common.time.seconds', { seconds: 120 }), seconds: 120 },
        { label: t('common.time.minutes', { minutes: 1 }), seconds: 60 },
        { label: t('common.time.minutes', { minutes: 5 }), seconds: 60 * 5 },
        { label: t('common.time.minutes', { minutes: 10 }), seconds: 60 * 10 },
        { label: t('common.time.minutes', { minutes: 15 }), seconds: 60 * 15 },
        { label: t('common.time.minutes', { minutes: 30 }), seconds: 60 * 30 },
        { label: t('common.time.hours', { hours: 1 }), seconds: 60 * 60 },
        { label: t('common.time.hours', { hours: 6 }), seconds: 60 * 60 * 6 },
        { label: t('common.time.hours', { hours: 12 }), seconds: 60 * 60 * 12 },
        { label: t('common.time.hours', { hours: 24 }), seconds: 60 * 60 * 24 }
    ];

    const syncRateOptions: SyncOption[] = [
        { label: '', limit: 0 },
        { label: t('common.limit.count', { limit: 1 }), limit: 1 },
        { label: t('common.limit.count', { limit: 5 }), limit: 5 },
        { label: t('common.limit.count', { limit: 10 }), limit: 10 },
        { label: t('common.limit.count', { limit: 20 }), limit: 20 },
        { label: t('common.limit.count', { limit: 50 }), limit: 50 },
        { label: t('common.limit.count', { limit: 75 }), limit: 75 },
        { label: t('common.limit.count', { limit: 100 }), limit: 100 }
    ];

    return { intervalOptions, syncRateOptions };
}
