import { Flag } from './../../../models/flag.enum';
import { CapturedData, CaptureParams, SkipValue } from 'src/models/captures/capturedData.model';
import { APIPaginationParams, transformPaginationParams } from '../pagination';
import { APIPlatform, readPlatform } from '../platform';
import { changeDateToStringDate } from '../../../utils/common';
import { DataType } from 'src/models/dataType.enum';
export interface APICaptureParams extends APIPaginationParams {
    id?:string;
    date?: Date;
    dateFirst?: Date|string;
    dateLast?:Date|string;
    clientCreatedAt?: Date | string;
    startDate?: string;
    endDate?: string;
    agencyId?: string;
    clientId?: string;
    deviceId?: string;
    userId?: string;
    flags?: string[];
    searchValue?: string;
    profileId?:string;
    sortBy?: string;
    isDateFilterChanged?: boolean;
    isDisableRecords?: boolean;
    alertsdatatype? : string;
    dataId?: string;
    skipRecordId?: string;
    alertwordsOnly?: boolean;
    deviceIds?: string;
    username?: string;
    skipValue?: SkipValue;
    isGridView?: boolean;
    exportSkipId?:number;
    exportlimit?:number;
    pdfExport?:boolean;
    dataType?:DataType|undefined | string
  
    platform?:string;
    version?:string;
    deviceStatus?: string[];
   
}

export interface APICaptureData {
    id: string;
    date?: Date;
    deviceId?: string;
    deviceAlias: string;
    userName: string;
    flag: Flag;
    platform: APIPlatform;
    capturedStartDate: string;
    capturedEndDate: string;
    clientCreatedAt: string;
    createdAt: string;
    isSeen?:boolean;
    _id:string
}

export function transformCaptureParams(
    params: CaptureParams
): APICaptureParams
{
    const {
        id,
        date,
        dateFirst,
        dateLast,
        agency,
        officer,
        client,
        device,
        username,
        clientCreatedAt,
        deviceIds,
        startDate,
        endDate,
        keyword,
        alert,
        flags,
        searchValue,
        profileValue,
        sortBy,
        status,
        isDateFilterChanged,
        isDisableRecords,
        alertsdatatype,
        dataId,
        skipRecordId,
        exportSkipId,
        pdfExport,
        exportlimit,
        alertwordsOnly,
        skipValue,
        isGridView,
        dataType,
        platform,
        version,
        deviceStatus,
        ...paginatedParams
    } = params;

    return {
        id,
        date,
        dateFirst,
        dateLast,
        username,
        clientCreatedAt,
        startDate: startDate ? changeDateToStringDate(new Date(startDate)) : startDate,
        endDate: endDate ? changeDateToStringDate(new Date(endDate)): endDate,
        userId: officer,
        agencyId: client ? undefined : agency,
        clientId: device ? undefined : client,
        deviceId: device,
        flags,
        deviceIds,
        profileId: profileValue,
        dataType,
        searchValue,
        sortBy,
        pdfExport,
        isDateFilterChanged,
        isDisableRecords,
        alertsdatatype,
        dataId,
        skipRecordId,
        alertwordsOnly,
        skipValue,
        isGridView,
        exportSkipId,
        exportlimit,
        platform:platform ?? undefined,
        version: version ?? undefined,
        deviceStatus,
        ...transformPaginationParams(paginatedParams),
    };
}

export function readAPICaptureData(
    data: APICaptureData
): CapturedData
{
    const
        {
            id,
            date,
            deviceAlias,
            userName,
            platform,
            flag: flags,
            capturedStartDate,
            capturedEndDate,
            clientCreatedAt,
            createdAt,
            deviceId,
            isSeen,
            _id,
        } = data;
    return {
        id: id?id:_id,
        date,
        device: deviceAlias,
        username: userName,
        platform: readPlatform(platform),
        flag: flags,
        captureStartDate: capturedStartDate,
        captureEndDate: capturedEndDate,
        capturedDate: clientCreatedAt,
        receivedDate: createdAt,
        deviceId,
        isSeen,
    };
}
