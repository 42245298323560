import React, { useState }from 'react';
import { Button, Grid, Stack, TextField } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { useTranslation } from 'react-i18next';
import { noop } from 'src/utils/common';

interface ConfirmationPanelProps {
    data: string,
    DeleteModal?: () => void;
    DeleteSubmit?:(data?: string) => void;
    type: string;
    typeFormat?: string;
}

function ConfirmationPanel({
    data,
    DeleteModal = noop,
    DeleteSubmit = noop,
    type,
    typeFormat = 'name',
}: Readonly<ConfirmationPanelProps>): React.ReactElement
{
    const { t } = useTranslation();
    const [deleteText, setDeleteText] = useState<string>();
    return (
        <>
            <Grid
                item
                sm={12}
                md={6}
            >
                <WarningIcon fontSize="small"/>
                <span style={{ position: 'absolute', marginLeft: '5px' }}>
                    Type the
                    {' '}
                    {type}
                    {' '}
                    {typeFormat ?? ''}
                    {' '}
                    <b>
                        {data.trim().replace(' ', '')}
                    </b>
                    {' '}
                    to delete
                </span>

                <TextField
                    fullWidth
                    autoComplete="off"
                    onChange={(e) => setDeleteText(e.target.value)}
                    required
                    autoFocus
                />
            </Grid>
            <Stack
                direction="row"
                justifyContent="flex-end"
                gap={1}
                padding="1rem 0"
            >
                <Button
                    type="button"
                    variant="contained"
                    onClick={DeleteModal}
                >
                    {t('common.actions.cancel')}
                </Button>
                <Button
                    type="button"
                    variant="contained"
                    disabled={data.trim().replace(' ', '') !== deleteText}
                    color="error"
                    onClick={() => DeleteSubmit(deleteText)}
                >
                    {t('common.actions.delete')}
                </Button>
            </Stack>
        </>
    );
}

export default ConfirmationPanel;
