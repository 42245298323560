import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Theme } from '@mui/material';
import {
    selectAgencyFilterOptions,
    selectClientFilterOptions,
    selectDeviceFilterOptions,
    selectAgencyFilterOptionsStatus,
    selectClientFilterOptionsStatus,
    selectDeviceFilterOptionsStatus,
    getAgencyFilterOptions,
    getOfficerFilterOptions,
    getClientFilterOptions,
    getDeviceFilterOptions,
    selectOfficerFilterOptions,
    selectOfficerFilterOptionsStatus,
    getVersionsFilterOptions,
    selectDeviceVersionFilterOptions,
    selectDeviceVersionFilterOptionsStatus,
} from 'src/state/captures/capturesSlice';
import { FiltersBar } from 'src/components/filters-bar/FiltersBar';
import {
    DropdownOption,
    DropdownSelector,
} from 'src/components/dropdown-selector/DropdownSelector';
import { CaptureFilters } from 'src/models/captures/capturedData.model';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    changeAgencyDeviceFilter,
    changeClientDeviceFilter,
    changeDeviceDetailsFilters,
    changeDeviceFilter,
    changeDevicePlatformFilter,
    changeDeviceStatusFilter,
    changeOfficerDeviceFilter,
    selectDeviceCountDetails,
    selectDeviceCountDetailsStatus,
    selectDeviceDetailsFilters,
    selectDeviceStatus,
} from 'src/state/administrationMeta/administrationMetaSlice';
import { DeviceStatus } from 'src/models/deviceSelectionModels';

export function DeviceInformationFilters(): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const officerFilters = useSelector(selectOfficerFilterOptions);
    const agencyFilters = useSelector(selectAgencyFilterOptions);
    const clientFilters = useSelector(selectClientFilterOptions);
    const deviceFilters = useSelector(selectDeviceFilterOptions);
    const devicesCount = useSelector(selectDeviceCountDetails);
    const devicesCountStatus = useSelector(selectDeviceCountDetailsStatus);
    const deviceVersionFilters = useSelector(selectDeviceVersionFilterOptions);
    const deviceVersionFiltersStatus = useSelector(selectDeviceVersionFilterOptionsStatus);
    const officerFiltersStatus = useSelector(selectOfficerFilterOptionsStatus);
    const agencyFiltersStatus = useSelector(selectAgencyFilterOptionsStatus);
    const clientFiltersStatus = useSelector(selectClientFilterOptionsStatus);
    const deviceFiltersStatus = useSelector(selectDeviceFilterOptionsStatus);
    const filters = useSelector(selectDeviceDetailsFilters);
    const deviceStatusFilter = useSelector(selectDeviceStatus);
    const platformOptions = devicesCount?.length ? devicesCount.map(device => ({
        name: device.platform,
        value: device.platform,
    })): [];
    function getdeviceStatusOptions(): DropdownOption[]
{
        return Object.entries(DeviceStatus).map(([name, value]) => ({
            name:value,
            value,
        }));
    }
    function onChangeSelectFilter(
        field: keyof CaptureFilters
    ): (value: unknown) => void
    {
        return value =>
        {
            dispatch(
                changeDeviceDetailsFilters({
                    [field]: value ? value as string : undefined,
                })
            );
        };
    }
    function onChangeAgencyFilter(agency: unknown): void
    {
        const agencyId = agency as string ?? undefined;
        const officerId = agencyId ? filters.officer : undefined;
        const clientId = agencyId ? filters.client : undefined;
        const platform = agencyId ? filters.platform : undefined;

        dispatch(changeAgencyDeviceFilter(agencyId));
        dispatch(getOfficerFilterOptions({ agencyId }));
        dispatch(getClientFilterOptions({
            agencyId,
            officerId,
        }));
        dispatch(getDeviceFilterOptions({
            agencyId,
            officerId,
            clientId,
        }));
        dispatch(getVersionsFilterOptions({
            agency: agencyId,
            officer: officerId,
            client: clientId,
            platform,
        }));
    }
    function onChangeOfficerFilter(officer: unknown): void
    {
        const officerId = officer as string ?? undefined;
        dispatch(changeOfficerDeviceFilter(officerId));
        dispatch(getClientFilterOptions({
            agencyId: filters.agency,
            officerId,
        }));
        dispatch(getDeviceFilterOptions({
            agencyId: filters.agency,
            officerId,
        }));
        dispatch(getVersionsFilterOptions({
            agency: filters.agency,
            officer: officerId,
            client: undefined,
            platform: undefined,
        }));
    }
    function onChangeClientFilter(client: unknown): void
    {
        const clientId = client as string ?? undefined;
        dispatch(changeClientDeviceFilter(clientId));
        dispatch(getDeviceFilterOptions({
            agencyId: filters.agency,
            officerId: filters.officer,
            clientId,
        }));
        dispatch(getVersionsFilterOptions({
            agency: filters.agency,
            officer: filters.officer,
            client: clientId,
            platform: undefined,
        }));
    }
    function onChangeDeviceFilter(device: unknown): void
    {
        const deviceId = device as string ?? undefined;
        dispatch(changeDeviceFilter(deviceId));
        dispatch(getVersionsFilterOptions({
            agency: filters.agency,
            officer: filters.officer,
            client: filters.client,
            device: deviceId,
            platform: undefined,
        }));
    }
    function onChangeDeviceStatusFilter(status: unknown): void
    {
      dispatch(changeDeviceStatusFilter(status as DeviceStatus[]));
    }
    function onChangePlatformFilter(platform: unknown): void
    {
        const platformValue = platform as string ?? undefined;
        dispatch(changeDevicePlatformFilter(platformValue ));
        dispatch(getVersionsFilterOptions({
            agency: filters.agency,
            officer: filters.officer,
            client: filters.client,
            device: filters.device,
            platform: platformValue,
        }));
    }
    useEffect(() =>
    {
        dispatch(getAgencyFilterOptions());
        dispatch(getOfficerFilterOptions({ agencyId: filters.agency }));
        dispatch(getClientFilterOptions({
            agencyId: filters.agency,
            officerId: filters.officer,
        }));
        dispatch(getDeviceFilterOptions({
            agencyId: filters.agency,
            officerId: filters.officer,
            clientId: filters.client,
        }));
        dispatch(getVersionsFilterOptions({
            agency: filters.agency,
            officer: filters.officer,
            client: filters.client,
            platform: filters.platform,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FiltersBar>
            <DropdownSelector
                options={agencyFilters ?? []}
                label={t('data-type.filters.agency')}
                labelId="agency-filter-label"
                value={filters.agency ?? ''}
                onChange={onChangeAgencyFilter}
                sx={{ width: '16rem' }}
                clearable={true}
                isLoading={agencyFiltersStatus === RequestStatus.InProgress}
                inputProps={{
                    sx: {
                        backgroundColor: (theme: Theme) => theme.palette.common.white,
                    },
                }}
            />
            <DropdownSelector
                options={officerFilters ?? []}
                label={t('data-type.filters.officer')}
                labelId="officer-filter-label"
                value={filters.officer ?? ''}
                onChange={onChangeOfficerFilter}
                sx={{ width: '16rem' }}
                clearable={true}
                isLoading={officerFiltersStatus === RequestStatus.InProgress}
                inputProps={{
                    sx: {
                        backgroundColor: (theme: Theme) => theme.palette.common.white,
                    },
                }}
            />
            <DropdownSelector
                options={clientFilters ?? []}
                label={t('data-type.filters.client')}
                labelId="client-filter-label"
                value={filters.client ?? ''}
                onChange={onChangeClientFilter}
                sx={{ width: '16rem' }}
                clearable={true}
                isLoading={clientFiltersStatus === RequestStatus.InProgress}
                inputProps={{
                    sx: {
                        backgroundColor: (theme: Theme) => theme.palette.common.white,
                    },
                }}
            />
            <DropdownSelector
                options={deviceFilters ?? []}
                label={t('data-type.filters.device')}
                labelId="device-filter-label"
                value={filters.device ?? ''}
                onChange={onChangeDeviceFilter}
                sx={{ width: '16rem' }}
                clearable={true}
                isLoading={deviceFiltersStatus === RequestStatus.InProgress}
                inputProps={{
                    sx: {
                        backgroundColor: (theme: Theme) => theme.palette.common.white,
                    },
                }}
            />
            <DropdownSelector
                options={platformOptions ?? []}
                label={t('data-type.table.platform')}
                labelId="platform-filter-label"
                value={filters.platform ?? ''}
                onChange={onChangePlatformFilter}
                sx={{ width: '16rem' }}
                clearable={true}
                isLoading={devicesCountStatus === RequestStatus.InProgress}
                inputProps={{
                    sx: {
                        backgroundColor: (theme: Theme) => theme.palette.common.white,
                    },
                }}
            />
            <DropdownSelector
                options={deviceVersionFilters ?? []}
                label={t('data-type.table.version')}
                labelId="version-filter-label"
                value={filters.version ?? ''}
                defaultSort={true}
                onChange={onChangeSelectFilter('version')}
                sx={{ width: '16rem' }}
                clearable={true}
                isLoading={deviceVersionFiltersStatus === RequestStatus.InProgress}
                inputProps={{
                    sx: {
                        backgroundColor: (theme: Theme) => theme.palette.common.white,
                    },
                }}
            />
              <DropdownSelector
                checkBoxSelection
                multiple
                options={getdeviceStatusOptions()}
                label={t('data-type.filters.device-status')}
                labelId="device-filter-label"
                value={deviceStatusFilter ??[]}
                onChange={onChangeDeviceStatusFilter}
                sx={{ width: '16rem' }}
                isLoading={deviceFiltersStatus === RequestStatus.InProgress}
                inputProps={{
                    sx: {
                        backgroundColor: (theme: Theme) => theme.palette.common.white,
                    },
                }}
            />
        </FiltersBar>
    );
}
