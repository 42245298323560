import {
    Device,
    DeviceCreationParams,
    DeviceParams,
    DeviceUpdateParams,
    PlatformVersionUpdateParams,
    PlatformVersionUpateResponse,
    DeviceLogParams,
    DeviceLogs,
    DevicePermissionsParams,
} from 'src/models/administration/deviceData.model';
import { PaginatedContent } from 'src/models/paginatedResult.model';
import {
    readAPIDeviceResponse,
    transformCreationDeviceParams,
    transformDeviceParams,
    transformDeviceUpdateParams,
    updatePlatformParams,
    platformAPIResponse,
    readAPIDeviceLogResponse,
    readAPIAgencyCurrentResponse,
    readAPIDevicePermissionResponse,
    DevicePermissionResponse,
} from '../data-transfer-objects/administration/devices';
import { readAPIPaginatedResponse } from '../data-transfer-objects/pagination';
import instance from '../instance';
import { VersionsParams } from '../data-transfer-objects/administration/agencies';

export async function getDevices(
    params: DeviceParams
): Promise<PaginatedContent<Device>>
{
    const response = await instance.get('device/list', {
        params: transformDeviceParams(params),
    });

    return readAPIPaginatedResponse(response.data, readAPIDeviceResponse);
}

export async function getDevice(deviceId: string): Promise<Device>
{
    const response = await instance.get(`device/${deviceId}`);

    return readAPIDeviceResponse(response.data);
}

export async function createDevice(
    params: DeviceCreationParams
): Promise<Device>
{
    const response = await instance.post(
        'device',
        transformCreationDeviceParams(params)
    );

    return readAPIDeviceResponse(response.data);
}

export async function updateDevice(
    params: DeviceUpdateParams
): Promise<Device>
{
    const response = await instance.patch(
        `device/${params.id}`,
        transformDeviceUpdateParams(params)
    );
    return readAPIDeviceResponse(response.data);
}

export async function updatePlatformVersion(
    params?: PlatformVersionUpdateParams
): Promise<PlatformVersionUpateResponse>
{
    const response = await instance.patch(
        `device/platformVersion/${params?.id}`,
        updatePlatformParams(params)
    );
    return platformAPIResponse(response.data);
}

export async function updateDownloadURL(
    params?: PlatformVersionUpdateParams
): Promise<PlatformVersionUpateResponse>
{
    const response = await instance.patch(
        `device/updateurl/${params?.id}`,
        updatePlatformParams(params)
    );
    return platformAPIResponse(response.data);
}

// eslint-disable-next-line
export async function deleteDevice(id: string)
{
    const response = await instance.post(
        `device/delete/${id}`
    );
    return response.data;
}

export async function getLogs(params: DeviceLogParams)
:Promise<PaginatedContent<DeviceLogs>>
{
    const response = await instance.get(
        'device/logs',
        { params }
    );
    return readAPIPaginatedResponse(response.data, readAPIDeviceLogResponse);
}

export async function getPermissions(params: DevicePermissionsParams)
:Promise<PaginatedContent<DevicePermissionResponse>>
{
    const response = await instance.get(
        'device/permissions',
        { params }
    );
    return readAPIPaginatedResponse(response.data, readAPIDevicePermissionResponse);
}

export async function getPermissionsIds(params: DevicePermissionsParams)
:Promise<string[]>
{
    const response = await instance.get(
        'device/permissionsIds',
        { params }
    );
    return response.data;
}

export async function getAgencyCompareOptions(
    data: { platformId?: string }
): Promise<VersionsParams>
{
    const response = await instance.patch('agency/versioncompare', {
        agencyId: data?.platformId,
    });
    return readAPIAgencyCurrentResponse(response.data);
}
