import { Dispatch, SetStateAction } from 'react';
import TableExporter from 'src/components/table-exporter/TableExporter';
import {
    changeAlertsIdStatus,
    changeAlertsJPEGStatus,
    changeSMSIdStatus,
    changeViewDataIdStatus,
    resetCardDataStatus,
    changeViewDataJPEGStatus,
    changeFilters,
} from 'src/state/captures/capturesSlice';
import { ALL_TO_EXPORT_BATCH_LIMIT, BATCH_LIMIT, REPORT_BATCH_LIMIT, SMS_BATCH_LIMIT } from './environment';
import { getAllToExportData } from 'src/services/administration/reports';
import { DataType } from 'src/models/dataType.enum';
import { CaptureFilters } from 'src/models/captures/capturedData.model';
import { ColumnData } from 'src/components/data-table/DataTable';
import { ALERTSPAGE, ALL_DATA_TYPE } from 'src/models/alertsType.enum';
import { GenerateReportParams } from 'src/models/captures/report.model';
import { xlsxImageDatatypes } from './reportsUtils';
import { resetDevicePermissionsStatus } from 'src/state/administrationMeta/administrationMetaSlice';

//  eslint-disable-next-line @typescript-eslint/no-empty-function, brace-style
export const noop = (): void => {};

export function sortByKey<Type>(array: Type[], key: keyof Type): Type[]
{
    function sorter(first: Type, second: Type): number
    {
        if (first[key] > second[key])
        {
            return 1;
        }
        else if (first[key] < second[key])
        {
            return -1;
        }
        return 0;
    }

    return array.sort(sorter);
}

export function removeDuplicates<Type>(array: Type[]): Type[]
{
    const collection = new Set<Type>(array);

    return Array.from(collection);
}

export function changeDateToString(date: Date): string
{
    return new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
    ).toISOString();
}
export function changeDateToStringDate(date: Date): string
{
    return new Date(date.getTime()).toISOString();
}

export function timeFormat(date: Date | string): Date | string
{
    if (date)
    {
        return new Date(date).toLocaleString(undefined, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true,
        });
    }
    return '';
}

export function formatSize(size: number): string
{
    const oneKb = 1024;
    const oneMb = oneKb * oneKb;

    if (size < oneKb)
    {
        return `${size} B`;
    }
    if (size < oneMb)
    {
        return `${(size / oneKb).toFixed(2)} KB`;
    }
    return `${(size / oneMb).toFixed(2)} MB`;
}

export const processAndGeneratePDFs = async (
    recordIds: string[],
    dataType: string,
    setDownloadPercentage: Dispatch<SetStateAction<number>>,
    setFormatAll: Dispatch<SetStateAction<string | undefined>> | null,
    setLoad: Dispatch<SetStateAction<boolean>> | null,
    // eslint-disable-next-line
    dispatch: Dispatch<any> | null
): Promise<void> =>
{
    setDownloadPercentage(0);
    let batchSize: number;

    if (dataType === 'sms')
    {
        batchSize = SMS_BATCH_LIMIT;
    }
    else if (dataType === 'Active Reporter' || dataType === 'Non Reporter')
    {
        batchSize = REPORT_BATCH_LIMIT;
    }
    else
    {
        batchSize = BATCH_LIMIT;
    }

    const totalRecords = recordIds.length;
    let processedRecords = 0;
    let currentChunkStartIndex = 0;
    const processChunk = async (startIndex: number): Promise<void> =>
    {
        const chunk = recordIds.slice(startIndex, startIndex + batchSize);
        if (chunk.length > 0)
        {
            await TableExporter.asAllPDF({
                type: dataType,
                recordIds: chunk,
                dataRange: `${currentChunkStartIndex + 1} - ${
                    currentChunkStartIndex + chunk.length
                }`,
                alltoExport: false,
            });
            processedRecords += chunk.length;
            currentChunkStartIndex = startIndex + chunk.length;
            const newPercentage = (processedRecords / totalRecords) * 100;
            setDownloadPercentage(newPercentage);
            await new Promise((resolve) => setTimeout(resolve, 1000));
            const nextIndex = startIndex + batchSize;
            if (nextIndex < recordIds.length)
            {
                await processChunk(nextIndex);
            }
            else
            {
                setDownloadPercentage(100);
                if (
                    (dataType === 'sms' ||
                        dataType === DataType.SMS ||
                        dataType === DataType.PHOTOS ||
                        dataType === DataType.SCREEN_CAPTURES ||
                        dataType === DataType.SCREENSHOTS ||
                        dataType === DataType.ACTIVE_REPORTER ||
                        dataType === DataType.NON_REPORTER ||
                        dataType === DataType.DEVICE_PERMISSIONS) &&
                    setFormatAll
                )
                {
                    setFormatAll(undefined);
                    if (dispatch)
                    {
                        dispatch(changeSMSIdStatus());
                        dispatch(changeAlertsIdStatus());
                        dispatch(changeViewDataIdStatus());
                        dispatch(resetCardDataStatus());
                        dispatch(resetDevicePermissionsStatus());
                    }
                }
                else if (dataType === 'Screenshots' && setLoad)
                {
                    setLoad(false);
                }
            }
        }
    };
    await processChunk(0);
};
export const processAndGenerateXlsx = async (
    totalRecord: number | undefined,
    dataType: DataType | undefined,
    filters: CaptureFilters|undefined,
    columns: ColumnData<DataType>[],
    exportParams: GenerateReportParams,
    isGeolocation?:boolean
): Promise<void> =>
{
    const batchSize = ALL_TO_EXPORT_BATCH_LIMIT;
    const totalRecords = totalRecord ? totalRecord : 0;
    const processChunk = async (startIndex: number): Promise<void> =>
    { 
        const isAlertsPage = window.location.pathname === ALERTSPAGE;
        let filterDataType;
        
        if (isAlertsPage)
        {
            filterDataType = DataType.ALERTS;
        }
        else
        {
            filterDataType = dataType;
        }
        const data = await getAllToExportData({
            ...filters,
            pageNumber: Math.floor(startIndex / batchSize) + 1,
            entriesPerPage: batchSize,
            exportSkipId: startIndex,
            exportlimit: batchSize,
            pdfExport: true,
            dataType: filterDataType,
            alertsdatatype: isAlertsPage ? filters?.alertsdatatype: undefined,
         
            deviceStatus: filters?.deviceStatus ?? undefined,
        });
        if (data.contents.length > 0)
        {
            const endIndex = startIndex + data.contents.length;
            const finalFilename = `export-${
                startIndex + 1
            }-${endIndex}-${new Date().toISOString()}`;
            const columnsWithImages = columns.some(
                (column) => column.label.includes('Image') ||
                (
                    isAlertsPage
                    && xlsxImageDatatypes.includes(exportParams?.type as DataType)
                    && column.label.includes('Details')
                )
            );
            if (columnsWithImages)
            {
                const exportIds = data.contents.map(data => data?.id);
                const updatedExportparams = {
                    ...exportParams,
                    recordIds: exportIds,
                };

                await TableExporter.asXLSXImages(columns, updatedExportparams, finalFilename);
            }
            else
            {
                await TableExporter.asXLSX(data.contents, columns, finalFilename);
            }

            const nextIndex = startIndex + batchSize;
            if (nextIndex < totalRecords)
            {
                await processChunk(nextIndex);
            }
        }
    };

    await processChunk(0);
};
export const processAndGenerateAllPdf = async (
    totalRecord: number | undefined,
    dataType: DataType | undefined | string,
    filters: CaptureFilters,
    setDownloadPercentage: Dispatch<SetStateAction<number>>,
    setLoad: Dispatch<SetStateAction<boolean>> | null,
    setFormatAll: Dispatch<SetStateAction<string | undefined>>
): Promise<void> =>
{
    const isAlertsPage = window.location.pathname === ALERTSPAGE;
    const batchSize = ALL_TO_EXPORT_BATCH_LIMIT;
    let processedRecords = 0;
    const totalRecords = totalRecord ? totalRecord : 0;
    const processChunk = async (startIndex: number): Promise<void> =>
    {
        const data = await getAllToExportData({
            ...filters,
            pageNumber: Math.floor(startIndex / batchSize) + 1,
            entriesPerPage: batchSize,
            exportSkipId: startIndex,
            exportlimit: batchSize,
            pdfExport: true,
            dataType: isAlertsPage? DataType.ALERTS :dataType,
            alertsdatatype:isAlertsPage ? filters.alertsdatatype: undefined
        });
        if (data.contents.length > 0)
        {
            const endIndex = startIndex + data.contents.length;
            const Ids = data.contents.map((item) =>
            {
                if(isAlertsPage && (filters.alertsdatatype===ALL_DATA_TYPE)){
                    return `${item.id}-${item.dataType}`;
                }
                else if (isAlertsPage && (filters.alertsdatatype === DataType.PROCESSES)) {
                    const detailsObject = Object.fromEntries(
                        item.details.split(',').map((data: string) => data.split(':').map((str: string) => str.trim()))
                    );
                
                    return {
                        ...item,   
                        localId: detailsObject.localId || '',
                        totalIntervalTime: detailsObject.totalIntervalTime || '',
                        name: detailsObject.name || ''
                    };
                }
                else if (dataType ===DataType.DEVICE_INFORMATION)
                {
                    return item.deviceId;
                }
                else
                {
                    return item.id || item._id;
                }
            });
            const filtersData = {
                ...filters,
                page:Math.floor(startIndex / batchSize) + 1,
                sizePerPage:batchSize,

            }
            await TableExporter.asAllPDF({
                type: isAlertsPage? filters.alertsdatatype:dataType === DataType.MMS?DataType.SMS:dataType,
                recordIds:
                    dataType === DataType.PROCESSES ? data.contents : Ids,
                dataRange: `${startIndex + 1} - ${endIndex}`,
                alltoExport: true,
                deviceId: filters.device,
                userName: data.contents[0].username,
                sortBy: filters?.sortBy,
                deviceInformationFilter: dataType === DataType.DEVICE_INFORMATION ?
                    filtersData:
                    undefined,
            });
            processedRecords += Ids.length;
            const newPercentage = (processedRecords / totalRecords) * 100;
            setDownloadPercentage(newPercentage);
            const nextIndex = startIndex + batchSize;
            if (nextIndex < totalRecords)
            {
                await processChunk(nextIndex);
            }
            else
            {
                setDownloadPercentage(100);
                setFormatAll(undefined);
                if (setLoad)
                {
                    setLoad(false);
                }
            }
        }
    };

    await processChunk(0);
};
type ImageUrl = string | { fileUrl: string }

export const downloadImages = async (
    // eslint-disable-next-line
    imageUrls: any,
    setIsLoading?: Dispatch<SetStateAction<boolean>> | null,
    setSelectLoading?: Dispatch<SetStateAction<boolean>> | null,
    fileId?: string,
    // eslint-disable-next-line
    dispatch?: Dispatch<any> | null
): Promise<void> =>
{
    try
    {
        setIsLoading?.(true);
        setSelectLoading?.(true);

        if (Array.isArray(imageUrls))
        {
            for (let index = 0; index < imageUrls.length; index++)
            {
                const imageUrl = getImageUrl(imageUrls[index]);

                const response = await fetch(imageUrl);

                if (!response.ok)
                {
                    continue;
                }
                downloadProcess(response, index);
            }
        }
        else
        {
            const imageUrl = getImageUrl(imageUrls);

            const response = await fetch(imageUrl);

            if (!response.ok)
            {
                return;
            }
            downloadProcess(response, undefined, fileId);
        }

        setIsLoading?.(false);
        setSelectLoading?.(false);
        if (dispatch)
        {
            dispatch(changeAlertsJPEGStatus());
            dispatch(changeViewDataJPEGStatus());
        }
    }
    catch (error)
    {
        setIsLoading?.(false);
        setSelectLoading?.(false);
    }
};

function getImageUrl(imageUrl: ImageUrl): string
{
    if (typeof imageUrl === 'string')
    {
        return imageUrl;
    }
    else
    {
        return imageUrl.fileUrl;
    }
}

async function downloadProcess(
    response: Response,
    index?: number,
    fileId?: string | undefined
): Promise<void>
{
    const imageArrayBuffer = await response.arrayBuffer();
    const file = new Blob([imageArrayBuffer], { type: 'image/*' });
    const element = document.createElement('a');
    element.href = URL.createObjectURL(file);
    element.download =
        index !== undefined ? `image_${index + 1}.jpeg` : `${fileId}.jpeg`;

    element.click();
}

export const zoomImage = (
    setScale: (scaleUpdater: (prevScale: number) => number) => void,
    setImagePosition: (position: { x: number; y: number }) => void,
    event: React.WheelEvent<HTMLDivElement>
): void =>
{
    setScale((prevScale) =>
    {
        const newScale = prevScale + (event.deltaY > 0 ? -0.5 : 0.5);
        if (newScale === 1)
        {
            setImagePosition({ x: 0, y: 0 });
        }
        return Math.max(1, newScale);
    });
};

export const handleDragStart = (
    scale: number,
    imagePosition: { x: number; y: number },
    setImagePosition: (position: { x: number; y: number }) => void,
    event: React.MouseEvent<HTMLDivElement>
): void =>
{
    if (scale !== 1)
    {
        event.preventDefault();
        const startX = event.clientX - imagePosition.x;
        const startY = event.clientY - imagePosition.y;

        let animationFrameId = 0;

        const handleMouseMove = (e: MouseEvent): void =>
        {
            e.preventDefault();
            cancelAnimationFrame(animationFrameId);

            animationFrameId = requestAnimationFrame(() =>
            {
                const newX = Math.round(e.clientX - startX);
                const newY = Math.round(e.clientY - startY);
                setImagePosition({ x: newX, y: newY });
            });
        };

        const handleMouseUp = (): void =>
        {
            if (animationFrameId !== 0)
            {
                cancelAnimationFrame(animationFrameId);
                animationFrameId = 0;
            }

            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);
    }
};

export const toggleFullScreen = (
    isFullScreen: boolean,
    setIsFullScreen: (isFullScreen: boolean) => void,
    setScale: (scale: number) => void,
    setImagePosition: (position: { x: number; y: number }) => void
): void =>
{
    if (isFullScreen)
    {
        setIsFullScreen(false);
        setScale(1);
        setImagePosition({ x: 0, y: 0 });
    }
    else
    {
        setIsFullScreen(true);
    }
};

export const validateEmail = (email: string): string | null =>
{
    const emailRegex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email))
    {
        return 'Invalid email address';
    }
    return null;
};

export const onPageChange = (
    pageNumber: number,
    setCurrentPage: (currentPage: number) => void
): void =>
{
    setCurrentPage(pageNumber);
};
export const updateEntriesPerPage = (
    entriesPerPage: number,
    setEntriesPerPage: (entriesPerPage: number) => void,
    setCurrentPage: (currentPage: number) => void,
    // eslint-disable-next-line
    dispatch: Dispatch<any>
): void =>
{
    setEntriesPerPage(entriesPerPage);
    onPageChange(1, setCurrentPage);
    dispatch(
        changeFilters({
            rowsPerPage: entriesPerPage,
        })
    );
};
