import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataTable, ColumnData } from 'src/components/data-table/DataTable';
import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    getDevicesDetails,
    selectDeviceDetails,
    selectDeviceDetailsFilters,
    selectDeviceDetailsStatus,
    selectDeviceStatus,
} from 'src/state/administrationMeta/administrationMetaSlice';
import { usePDFExportSetter } from 'src/components/table-exporter/TableExporterProvider';
import { DataType } from 'src/models/dataType.enum';
import { DeviceDetails } from 'src/models/administration/deviceDetailsData.model';
import {
    changeColumns,
    changeTableDataType,
    changeTotalRecord,
} from 'src/state/captures/capturesSlice';

export function DeviceInformationTable(): React.ReactElement
{
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const { t } = useTranslation();
    const pdfExportSetter = usePDFExportSetter();
    const dispatch = useDispatch();
    const devicesData = useSelector(selectDeviceDetails);
    const filters = useSelector(selectDeviceDetailsFilters);
    const status = useSelector(selectDeviceDetailsStatus);
    const deviceStatusFilter = useSelector(selectDeviceStatus);
    const [currentPage, setCurrentPage] = useState(1);
    function onPageChange(pageNumber: number): void
    {
        dispatch(getDevicesDetails({ pageNumber, entriesPerPage, ...filters,deviceStatus:deviceStatusFilter }));
        setCurrentPage(pageNumber);
    }

    useEffect(() =>
    {
        dispatch(getDevicesDetails({ pageNumber: 1, entriesPerPage, ...filters ,deviceStatus:deviceStatusFilter}));
        setCurrentPage(1);
    }, [dispatch, entriesPerPage, filters,deviceStatusFilter]);

    function getChipColor(
        enabled: boolean,
        activated: boolean
    ): 'error' | 'success' | 'warning' | undefined
    {
        if (!enabled)
        {
            return 'error';
        }

        if (!activated)
        {
            return 'warning';
        }

        return 'success';
    }
    function getChipLabel(enabled: boolean, activated: boolean): string
    {
        if (!enabled)
        {
            return t('common.adjectives.disabled');
        }

        if (!activated)
        {
            return t('common.status.inactive');
        }

        return t('common.status.active');
    }
    const getStatusColumn = (device: DeviceDetails): React.ReactNode => (
        <Chip
            label={getChipLabel(device.enabled, device.activated)}
            color={getChipColor(device.enabled, device.activated)}
            size="small"
        />
    );

    const columns: ColumnData<DeviceDetails>[] = [
        {
            label: t('common.nouns.device'),
            size: 1,
            value: (data: DeviceDetails) => data.deviceId,
        },
        {
            label: t('data-type.details.device-alias'),
            size: 2,
            value: (data: DeviceDetails) => data.deviceAlias,
        },
        {
            label: t('common.nouns.agency'),
            size: 2,
            value: (data: DeviceDetails) => data.agency,
        },
        {
            label: t('data-type.filters.client'),
            size: 2,
            value: (data: DeviceDetails) => data.client,
        },
        {
            label: t('form.rcom-account'),
            size: 1,
            value: (data: DeviceDetails) => data.clientRCOM,
        },
        {
            label: t('data-type.table.device-type'),
            size: 1,
            value: (data: DeviceDetails) => data.platform,
        },
        {
            label: t('common.nouns.status'),
            size: 1,
            value: (data: DeviceDetails) => getChipLabel(data.enabled, data.activated),
            format: (data: DeviceDetails) => getStatusColumn(data),
        },
        {
            label: t('data-type.table.device-model'),
            size: 2,
            value: (data: DeviceDetails) => data.deviceModel ?? '-',
        },
        {
            label: t('data-type.table.version'),
            size: 1,
            value: (data: DeviceDetails) => data.deviceVersion ?? '-',
        },
        {
            label: t('data-type.table.os-version'),
            size: 1,
            value: (data: DeviceDetails) => data.deviceOSVersion ?? '-',
        },
    ];

    useEffect(() =>
    {
        if (devicesData)
        {
            const updatedData = {
                ...filters,
                agencyId:filters.agency,
                clientId:filters.client,
                deviceId:filters.device,
                userId:filters.user,
            }
            pdfExportSetter({
                type: DataType.DEVICE_INFORMATION,
                recordIds: devicesData.contents.map((item) => item.deviceId),
                deviceInformationFilter:
                {
                    page: currentPage,
                    sizePerPage: entriesPerPage,
                    ...updatedData,
                },
            });
            dispatch(changeColumns(columns));
            dispatch(changeTotalRecord(devicesData.numberOfEntries));
            dispatch(changeTableDataType(DataType.DEVICE_INFORMATION));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [devicesData, pdfExportSetter]);

    return (
        <DataTable<DeviceDetails>
            isLoading={status === RequestStatus.InProgress}
            columns={columns}
            onPageChange={onPageChange}
            entriesPerPage={entriesPerPage}
            onChangeEntriesPerPage={setEntriesPerPage}
            {...devicesData}
        />
    );
}
