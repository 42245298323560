import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAgencies,
    selectAgencies,
    selectAgencyDeleteStatus,
    selectAgencyFilters,
    selectAgencySaveStatus,
    selectAgencyStatus,
    selectAdminFilters,
} from 'src/state/administration/administrationSlice';
import { DataTable, ColumnData } from 'src/components/data-table/DataTable';
import { useTranslation } from 'react-i18next';
import BuildIcon from '@mui/icons-material/Build';
import EditIcon from '@mui/icons-material/Edit';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { RequestStatus } from 'src/models/requestStatus.enum';
import { Agency } from 'src/models/administration/agencyData.model';
import { Alert, AlertProps, Button, Snackbar, SnackbarProps } from '@mui/material';
import { noop } from 'src/utils/common';
import Update from '@mui/icons-material/ManageHistory';
import { AgencyVersionUpdateModal } from '../agency-version-update-modal/AgencyVersionUpdateModal';

export interface AgenciesTableProps {
    onEdit?: (agency: Agency) => void;
    onConfig?: (agency: Agency) => void;
    onAlert?: (agency: Agency) => void;
}

export function AgenciesTable({
    onEdit = noop,
    onConfig = noop,
    onAlert = noop,
}: Readonly<AgenciesTableProps>): React.ReactElement

{
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const items = useSelector(selectAgencies);
    const filters = useSelector(selectAgencyFilters);
    const status = useSelector(selectAgencyStatus);
    const saveStatus = useSelector(selectAgencySaveStatus);
    const deleteStatus = useSelector(selectAgencyDeleteStatus);
    const searchFilter = useSelector(selectAdminFilters);
    const [versionCreateModal, setVersionCreateModal] = useState<boolean>(false);
    const [notificationState, setNotificationState] = useState<SnackbarProps & AlertProps>();
    const [datas, setDatas] = useState<{ id: string; name: string; }>();

    function onPageChange(pageNumber: number): void
    {
        dispatch(getAgencies({ pageNumber, entriesPerPage, ...searchFilter }));
    }

    function showVersionUpdate(data: Agency): void
    {
        setVersionCreateModal(true);
        setDatas({ id: data.id, name: data.agencyName });
    }

    useEffect(() =>
    {
        if (saveStatus === RequestStatus.Success || deleteStatus === RequestStatus.Success)
        {
            dispatch(getAgencies({ pageNumber: 1, entriesPerPage, ...searchFilter }));
        }
    }, [saveStatus, dispatch, entriesPerPage, deleteStatus, searchFilter]);

    useEffect(() =>
    {
        dispatch(getAgencies({ pageNumber: 1, entriesPerPage, ...filters, ...searchFilter }));
    }, [dispatch, entriesPerPage, filters, searchFilter]);

    function closeModal(): void
    {
        setVersionCreateModal(false);
    }
    function onCreateComplete(): void
    {
        const subject = t('data-type.table.version');
        setNotificationState({
            open: true,
            message: t('common.messages.saved-successfully', { subject }),
        });
        setTimeout(() => setNotificationState({
            open: false,
        }), 1000);
    }

    const getAgencyVersionButton = (agency: Agency): React.ReactNode => (
        <Button
            aria-label="edit"
            onClick={() => showVersionUpdate(agency)}
        >
            <Update />
        </Button>
    );

    const getAgencyEditButton = (agency: Agency): React.ReactNode => (
        <Button
            onClick={() => onEdit(agency)}
        >
            <EditIcon />
        </Button>
    );

    const getAgencyConfigurationButton = (agency: Agency): React.ReactNode => (
        <Button onClick={() => onConfig(agency)}>
            <BuildIcon />
        </Button>
    );

    const getAgencyAlertWordsButton = (agency: Agency): React.ReactNode => (
        <Button onClick={() => onAlert(agency)}>
            <ListAltIcon />
        </Button>
    );

    const columns: ColumnData<Agency>[] = [
        {
            label: t('common.nouns.id'),
            size: 3,
            value: (data: Agency) => data.id,
        },
        {
            label: t('common.nouns.name'),
            size: 12,
            value: (data: Agency) => data.agencyName,
        },
        {
            label: t('common.actions.agency-version'),
            size: 1,
            format: (agency: Agency) => getAgencyVersionButton(agency),
        },
        {
            label: t('common.actions.edit'),
            size: 1,
            align: 'center',
            format: (agency) => getAgencyEditButton(agency),
        },
        {
            label: t('common.nouns.configuration'),
            size: 1,
            align: 'center',
            format: (agency) => getAgencyConfigurationButton(agency),
        },
        {
            label: t('data-type.details.alert-words'),
            size: 1,
            align: 'center',
            format: (agency) => getAgencyAlertWordsButton(agency),
        },
    ];

    return (
        <>
            <Snackbar
                autoHideDuration={6000}
                open={notificationState?.open}
            >
                <Alert
                    severity={'success'}
                >
                    {notificationState?.message}
                </Alert>
            </Snackbar>
            <DataTable<Agency>
                isLoading={status === RequestStatus.InProgress}
                columns={columns}
                onPageChange={onPageChange}
                entriesPerPage={entriesPerPage}
                onChangeEntriesPerPage={setEntriesPerPage}
                {...items}
            />
            {
                versionCreateModal && (
                    <AgencyVersionUpdateModal
                        open={versionCreateModal}
                        datas={datas}
                        onClose={closeModal}
                        onActionComplete={onCreateComplete}
                    />
                )
            }
        </>
    );
}
