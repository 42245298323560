import { Button, Checkbox, Grid, Stack, TextField, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'src/components/modal/Modal';
import { noop } from 'src/utils/common';
import {
    PlatformVersion,
} from 'src/models/administration/platformVersionData.model';
import { ColumnData, DataTable } from 'src/components/data-table/DataTable';
import { useSelector, useDispatch } from 'react-redux';
import {
    getnoncompliantAgencyDetails,
    selectNonCompliantAgency,
    selectNonCompliantAgencyStatus,
    resetNonComplianceAgency,
    updateVersion,
} from 'src/state/administration/administrationSlice';
import { selectAgencyFilterOptions } from 'src/state/captures/capturesSlice';
import { RequestStatus } from 'src/models/requestStatus.enum';
export interface AgencyVersion {
    agencyName?: string;
    version?: string;
    agencyId: string;
}
export interface VersionAgencyModalProps {
    // eslint-disable-next-line
    data?: any;
    // eslint-disable-next-line
    versionData?: any,
    // eslint-disable-next-line
    editData?: any;
    versionPlatform?: string,
    agencyType?: string;
    open?: boolean;
    onClose?: () => void;
    onActionComplete?: () => void;
    nonComplianceAgencyIds?: string[];
}


export function VersionAgencyModal({
    data,
    versionData,
    versionPlatform,
    agencyType,
    nonComplianceAgencyIds,
    open = true,
    editData,
    onClose = noop,
    onActionComplete = noop,
}: Readonly<VersionAgencyModalProps>): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const nonComplianceData = useSelector(selectNonCompliantAgency);
    const nonComplianceDataStatus = useSelector(selectNonCompliantAgencyStatus);
    const agencyFilters = useSelector(selectAgencyFilterOptions);
    const platformData: PlatformVersion = editData;
    // eslint-disable-next-line
    const [agencyData, setAgencyData] = useState<any>();
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [confirmationModal,setConfirmationModal]=useState(false)
    const [searchValue,setSearchValue]=useState<string>("");
    const [selectedAgencies, setSelectedAgencies] = useState<string[]>([]);
    const [loader, setLoader] = useState(false);

    function onPageChange(pageNumber: number): void
    {
        setPage(pageNumber);
        if (agencyType === 'non-compliant')
        {
            dispatch(getnoncompliantAgencyDetails({
                agencyIds: nonComplianceAgencyIds,
                platform: versionPlatform,
                version: editData?.version,
                pageNumber,
                entriesPerPage,
            }));
        }
    }
    function onEntriesPerChange(entriesPerPage: number): void
    {
        setEntriesPerPage(entriesPerPage);
        setPage(1);
    }
    useEffect(() =>
    {
        if (agencyType === 'non-compliant')
        {
            dispatch(getnoncompliantAgencyDetails({
                agencyIds: nonComplianceAgencyIds,
                platform: versionPlatform,
                version: editData?.version,
                pageNumber: page,
                entriesPerPage,
                searchValue:searchValue
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entriesPerPage,searchValue]);

    useEffect(() =>
    {
        if ((agencyType === 'non-compliant') && (editData.agencyIds?.length))
        {
            // eslint-disable-next-line
            const noncomplianceAgencyId = editData?.agencyIds.map((item: { agency: any; }) =>
            {
                return item.agency;
            });
            const filterData = agencyFilters?.filter((item: { value: string; }) =>
                !noncomplianceAgencyId.includes(item.value));
            const ids = filterData?.map((item) => item.value);

            dispatch(getnoncompliantAgencyDetails({
                agencyIds: ids,
                platform: editData?.platform,
                version: editData?.version,
                pageNumber: 1,
                entriesPerPage: 10,
            }));

        }
        else
        {
            const ids = agencyFilters?.map((item) => item.value);

            dispatch(getnoncompliantAgencyDetails({
                agencyIds: ids,
                platform: data?.platform,
                version: data?.version,
                pageNumber: 1,
                entriesPerPage: 10,
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        if (data && (agencyType === 'compliant'))
        {
            const filteredData = data.contents.filter((item: {
                agencyName
                : string;
            }) =>
                item.agencyName.toLowerCase().includes(searchValue.toLowerCase())
            );
            
            const startIndex = (page - 1) * entriesPerPage;
            const endIndex = startIndex + entriesPerPage;
            const paginatedArray = searchValue?filteredData.slice(startIndex, endIndex):  data?.contents.slice(startIndex, endIndex);

            if ((agencyType === 'compliant') && (paginatedArray.length))
            {
                setLoader(true);
                setAgencyData({
                    contents: paginatedArray,
                    currentPage: page,
                    numberOfEntries: searchValue?filteredData.length: data?.contents.length,
                });
                setTimeout(() => {
                    setLoader(false);
                }, 1000);
            }
            else
            {
                setAgencyData([])
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, page, entriesPerPage,searchValue]);

    useEffect(() =>
    {
        if( (agencyType === 'non-compliant')){
        if ((nonComplianceData?.length > 0) &&
            (agencyFilters?.length))
        {
            let agencyArray: { agencyName: string; agencyId: string }[] = [];
            const ids = nonComplianceData?.map((item: { agency: string; }) => item.agency);
            const filterData = agencyFilters?.filter((item: { value: string; }) =>
                ids.includes(item.value));

            filterData?.map((agency: {
                value: string; name: string;
            }) =>
            {
                return agencyArray.push({
                    agencyName: agency.name,
                    agencyId: agency.value,
                });
            });
            if(searchValue){
                agencyArray = agencyArray.filter((item: { agencyName
                    : string; }) =>
        item.agencyName.toLowerCase().includes(searchValue.toLowerCase())
      );
            }
            if (agencyArray.length)
            {
                setAgencyData({
                    contents: agencyArray,
                    currentPage: page,
                    numberOfEntries: searchValue ? agencyArray.length:nonComplianceAgencyIds?.length,
                });
            }
            else{
                setAgencyData([])
            }
        }
        else
        {
                setAgencyData([])
        }
     }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nonComplianceData, page]);

    function batchupdateChange(data: AgencyVersion): void
    {
        const agencyId = platformData.agencyIds?.map((item) => item.agency);
        const updatedId = agencyId?.concat(data.agencyId);
        dispatch(updateVersion({
            agencyIds: updatedId,
            downgrade: platformData.downgrade,
            downloadUrl: platformData.downloadUrl,
            id: platformData.id,
            platform: platformData.platform,
            version: platformData.version,
        }));
        onActionComplete();
        onClose();
    }
    function allAgencyBatchUpdateChange(data:string[]):void{
        const agencyId =platformData.agencyIds? platformData.agencyIds?.map((item) => item.agency):[];
        const updatedId = [...agencyId, ...selectedAgencies];
        dispatch(updateVersion({
            agencyIds: updatedId,
            downgrade: platformData.downgrade,
            downloadUrl: platformData.downloadUrl,
            id: platformData.id,
            platform: platformData.platform,
            version: platformData.version,
        }));
        onActionComplete();
        onClose();
    }
    const getVersionData = (data: AgencyVersion): React.ReactNode =>
    {

        let currentVersions;
        if ((nonComplianceData) && (agencyType === 'non-compliant'))
        {
            const currentVersionDetails = nonComplianceData?.find(
                (agency: { agency: string | undefined; }) =>
                    agency.agency === data.agencyId
            );

            const currentVersionData = currentVersionDetails?.currentVersion;
            if (currentVersionData !== undefined)
            {
                currentVersions = (( currentVersionData[0].currentVersions ) &&
                ( currentVersionData[0].currentVersions.length > 0 ) &&
                currentVersionData[0].currentVersions.find(
                    (agency: {
                        platform: string | undefined; agency: string | undefined;
                    }) =>
                        agency.platform === versionPlatform
                )?.version ) || 0;
            }
        }
        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                {agencyType === 'compliant' ? (
                    <button style={{ margin: '0', border: 'none', background: 'none' }}>
                        {data.version}
                    </button>
                ) : (
                    <button style={{ margin: '0', border: 'none', background: 'none' }}>
                        {currentVersions !== undefined && currentVersions !== 0 ?
                            currentVersions
                            : '-'
                        }
                    </button>
                )}
                {agencyType === 'non-compliant' && (
                    <>
                    
                    <Button
                        style={{ margin: '0' }}
                        variant="outlined"
                        onClick={() =>
                        {
                            batchupdateChange(data);
                        }}
                    >
                         {t('data-type.details.add-batch-update')}
                    </Button>
                    {nonComplianceAgencyIds?.length ?
                      <Checkbox
                        checked={selectedAgencies.includes(data.agencyId)}
                        onChange={() => handleCheckboxChange(data.agencyId)}
                  />
                  :''}
                  </>
                )}
            </div>
        );

    };
    const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>):void => {
        const isChecked = event.target.checked;
        if (isChecked) {
            const allAgencyIds = nonComplianceAgencyIds?.map((agency: string) => agency);
            if(allAgencyIds?.length){
            setSelectedAgencies(allAgencyIds);
            }
        } else {
            setSelectedAgencies([]);
        }
    };
    const handleCheckboxChange = (agencyId: string):void => {
        setSelectedAgencies(prevSelected =>
            prevSelected.includes(agencyId)
                ? prevSelected.filter(id => id !== agencyId) 
                : [...prevSelected, agencyId] 
        );
        
    };
    const columns = [
        {
            label: t('form.agency'),
            size: 5,
            value: (data: AgencyVersion) =>
            {
                const matchingAgency =
                    agencyType === 'compliant' ? versionData?.find(
                        (agency: { agency: string | undefined; }) => agency.agency === data.agencyId
                    ) : nonComplianceData?.find(
                        (agency: { agency: string | undefined; }) => agency.agency === data.agencyId
                    );
                const PlatformActiveCount =
                    matchingAgency?.particularPlatform?.find(
                        (platform: { platform: string }) =>
                            platform.platform === versionPlatform
                    )?.totalDevices || 0;
                return data.agencyName + (matchingAgency
                    ? ` (${PlatformActiveCount})`
                    : '');
            },
        },
        {
            label: (
                <>
                {agencyType === 'compliant' ?  <span>{t('data-type.table.version')}</span>:
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <span>{t('data-type.table.version')}</span>
        {nonComplianceAgencyIds?.length ?
        <Checkbox
            
            style={{ marginLeft: 'auto' }}
            onChange={handleSelectAllChange}
            checked={selectedAgencies.length === nonComplianceAgencyIds?.length}
            inputProps={{ 'aria-label': 'Select Agency' }}
        />:''}
    </div>}
                </>
               
            ),
            size: 4,
            value: (data: AgencyVersion) => getVersionData(data),
        },
        agencyType === 'compliant' && {
            label: t('data-type.table.completed'),
            size: 3,
            value: (data: AgencyVersion) =>
            {
                const matchingAgency =
                    agencyType === 'compliant' ? versionData?.find(
                        (agency: { agency: string | undefined; }) => agency.agency === data.agencyId
                    ) : nonComplianceData?.find(
                        (agency: { agency: string | undefined; }) => agency.agency === data.agencyId
                    );
                const PlatformUpdatedCount =
                    matchingAgency?.particularPlatformUpdatedDeviceData?.find(
                        (platform: { platform: string }) =>
                            platform.platform === versionPlatform
                    )?.updatedDevices?.length || 0;
                return PlatformUpdatedCount;
            },
        },
        agencyType === 'compliant' && {
            label: t('data-type.table.non-completed'),
            size: 3,
            value: (data: AgencyVersion) =>
            {
                const matchingAgency =
                    agencyType === 'compliant' ? versionData?.find(
                        (agency: { agency: string | undefined; }) => agency.agency === data.agencyId
                    ) : nonComplianceData?.find(
                        (agency: { agency: string | undefined; }) => agency.agency === data.agencyId
                    );
                const PlatformNotUpdatedCount =
                    matchingAgency?.particularPlatformUpdatedDeviceData?.find(
                        (platform: { platform: string }) =>
                            platform.platform === versionPlatform
                    )?.notUpdatedDevices?.length || 0;
                return PlatformNotUpdatedCount;
            },
        },
    ].filter(Boolean) as ColumnData<AgencyVersion>[];

    function onChangeSelectFilter(value: string): void {
        setSearchValue(value);
        setPage(1);
    }

    return (
        <Modal
            title={
                agencyType === 'compliant' ?
                    t('data-type.details.compliant-agency')
                    : t('data-type.details.non-compliant-agency')
            }
            open={open}
            showCloseButton={true}
            maxWidth="md"
            fullWidth
            onClose={()=>{
                onClose();
                setAgencyData([]);
                dispatch(resetNonComplianceAgency());
            }}
        >
            <>
            {agencyType === 'non-compliant' && nonComplianceAgencyIds?.length ?
            
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '2%' }}>
                <TextField
                name="firstname"
                label={ t('data-type.table.search')}
                autoComplete="off"
                style={{ float: 'right' }}
                sx={{ mb: 1 ,marginLeft:'2%'}}
                onChange={event => onChangeSelectFilter(event.target.value)}
            />
              <Button
                   
                        variant="outlined"
                        onClick={() =>
                        {
                            setConfirmationModal(true)
                        }}
                        sx={{ mb: 1,marginLeft:'2%'}}
                        disabled ={selectedAgencies.length === 0}
                    >
                       {t('data-type.details.add-agency')}
                    </Button>
                    </div>:''
} 
{
    agencyType==='compliant'?
    <TextField
                name="firstname"
                label={ t('data-type.table.search')}
                autoComplete="off"
                style={{ float: 'right' }}
                sx={{ mb: 1 ,marginLeft:'2%'}}
                onChange={event => onChangeSelectFilter(event.target.value)}
            />:''
}
            <DataTable<AgencyVersion>
                isLoading={nonComplianceDataStatus === RequestStatus.InProgress || loader}
                columns={columns}
                onPageChange={onPageChange}
                entriesPerPage={entriesPerPage}
                onChangeEntriesPerPage={onEntriesPerChange}
                datas={agencyData}
                {...agencyData}
            />
         
            <Modal
            title={t('data-type.filters.confirmation-alert')}
            open={confirmationModal}
            showCloseButton={true}
            maxWidth="md"
            fullWidth
            onClose={()=>{
                setConfirmationModal(false);
            }}
            >
<>

            <Grid    
            container
            direction="column"
            alignItems="center"
            spacing={2}>
                <Grid item>
                <Typography
                    component="span"
                    sx={{ display: 'flex', alignItems: 'center', fontSize: '18px' }}
                >
                  
                    {t('data-type.details.non-compliant-confirmation')}
                         {' '}
                    {' '}
                    <b style={{ marginLeft: '5px' }}>{`${selectedAgencies.length}`}</b>
                    {' '}
                    <span style={{ marginRight: '5px' }} />
                    {t('data-type.details.non-compliant-confirmation-version')}
                   
                    {' '}
                    {platformData.version} ?
                    {' '}
                   
                </Typography>
            </Grid>
            <Grid item>
                <Stack direction="row" justifyContent="center" spacing={2}>
                    <Button
                        type="button"
                        variant="contained"
                        onClick={() => {
                            setConfirmationModal(false);
                        }}
                    >
                        No
                    </Button>
                    <Button
                        type="button"
                        variant="contained"
                        color="error"
                         onClick={() => {
                            const ids = agencyFilters?.map((item) => item.value);
                            if(ids?.length)
                            allAgencyBatchUpdateChange(ids);
                         }}
                    >
                        Yes
                    </Button>
                </Stack>
            </Grid>
            </Grid>
            </>
            </Modal>
              </>
        </Modal>
      
    );
}
