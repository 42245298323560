import { PaginatedContent } from 'src/models/paginatedResult.model';
import { readAPIPaginatedResponse } from '../data-transfer-objects/pagination';
import instance from '../instance';
import {
    DeleteDeviceCacheParams,
    DeviceCacheDetails,
    DeviceCacheDetailsPaginatedParams
} from 'src/models/administration/deviceCacheDetailsData.model';
import { 
    readAPIDeviceCacheDetailsResponse,
    transformDeleteDeviceCacheParams,
    transformDeviceCachePaginatedParams
} from '../data-transfer-objects/administration/deviceCacheDetails';

export async function getDevicesCacheDetails(
    params: DeviceCacheDetailsPaginatedParams
): Promise<PaginatedContent<DeviceCacheDetails>>
{
    const response = await instance.get('device-detail/cache/list', {
        params: transformDeviceCachePaginatedParams(params),
    });

    return readAPIPaginatedResponse(response.data, readAPIDeviceCacheDetailsResponse);
}

export async function deleteDeviceCacheData(
    params: DeleteDeviceCacheParams
): Promise<void>
{
    const response = await instance.post(
        'device-detail/delete/cache',
        transformDeleteDeviceCacheParams(params),
    );
    
    return response.data;
}
