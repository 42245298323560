import {
    DeviceDetails,
    DeviceDetailsPaginatedParams,
    DeviceDetailsParams,
    DevicesCountDetails,
} from 'src/models/administration/deviceDetailsData.model';
import { APIPaginationParams, transformPaginationParams } from '../pagination';
import { Platform } from 'src/models/platforms.enum';

export interface APIDeviceDetailsPaginatedParams
    extends APIDeviceDetailsParams, APIPaginationParams {}

export interface APIDeviceDetailsParams {
    deviceId?: string;
    clientId?: string;
    agencyId?: string;
    userId?: string;
    platform?: string;
    version?: string;
    searchValue?: string;
    deviceStatus?: string[];
}

export interface APIDeviceDetailsResponse {
    deviceId: string;
    deviceAlias: string;
    agency: string;
    client: string;
    clientRCOM: string;
    platform: Platform;
    activated: boolean;
    enabled: boolean;
    deviceModel: string;
    deviceVersion: string;
    deviceOSVersion: string;
}
export interface APIDeviceCountDetailsResponse {
    platform: string;
    activeCount: number;
    inactiveCount: number;
}

export function transformDeviceDetailsParams(params: DeviceDetailsParams): APIDeviceDetailsParams
{
    const { device, client, agency, officer, platform, version } = params;

    return {
        deviceId: device,
        clientId: client,
        agencyId: agency,
        userId: officer,
        platform,
        version,
    };
}

export function transformDeviceDetailsPaginatedParams
(params: DeviceDetailsPaginatedParams): APIDeviceDetailsPaginatedParams
{
    const {
        device, client, agency, officer, platform, version, searchValue,deviceStatus, ...paginationParams
    } = params;

    return {
        deviceId: device,
        clientId: client,
        agencyId: agency,
        userId: officer,
        platform,
        version,
        searchValue,
        deviceStatus,
        ...transformPaginationParams(paginationParams),
    };
}

export function readAPIDeviceDetailsResponse(data: APIDeviceDetailsResponse): DeviceDetails
{
    return {
        deviceId: data.deviceId,
        deviceAlias: data.deviceAlias,
        agency: data.agency,
        client: data.client,
        clientRCOM: data.clientRCOM,
        platform: data.platform,
        activated: data.activated,
        enabled: data.enabled,
        deviceModel: data.deviceModel,
        deviceVersion: data.deviceVersion,
        deviceOSVersion: data.deviceOSVersion,
    };
}

export function readAPIDeviceCountDetailsResponse(
    data: APIDeviceCountDetailsResponse[]
): DevicesCountDetails[]
{
    return data.map(device => ({
        platform: device.platform,
        activeCount: device.activeCount,
        inactiveCount: device.inactiveCount,
    }));
}

