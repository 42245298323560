import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TableExporterProvider from 'src/components/table-exporter/TableExporterProvider';
import { CircularProgress, Grid, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import TableDownloadBar from 'src/components/table-download-bar/TableDownloadBar';
import { DeviceInformationTable } from './DeviceInformationTable';
import { DeviceInformationFilters } from './DeviceInformationFilters';
import {
    getDevicesCountDetails,
    selectDeviceCountDetails,
    selectDeviceCountDetailsStatus,
    selectDeviceDetailsFilters,
} from 'src/state/administrationMeta/administrationMetaSlice';
import { Platform } from 'src/models/platforms.enum';
import { RequestStatus } from 'src/models/requestStatus.enum';
import { AdminSettingSearchFilter } from '../admin-setting-search-filter/AdminSettingSearchBar';

export function DeviceInformationPage(): JSX.Element
{
    const dispatch = useDispatch();
    const filters = useSelector(selectDeviceDetailsFilters);
    const devicesCount = useSelector(selectDeviceCountDetails);
    const devicesCountStatus = useSelector(selectDeviceCountDetailsStatus);

    useEffect(() =>
    {
        if(!filters.searchValue)
        {
            dispatch(getDevicesCountDetails({ ...filters }));
        }
    }, [dispatch, filters]);

    return (
        <>
            <section
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                }}
            >
                {
                    Object.values(Platform).map((platform) =>
                    {
                        const device = devicesCount?.find(
                            (device) => device.platform === platform
                        );
                        return (
                            <Card
                                key={platform}
                                sx={{
                                    width: 175,
                                    height: 120,
                                    mt: 2,
                                    mb: 2,
                                    boxShadow: 6,
                                    borderRadius: 3,
                                    position: 'relative',
                                    backgroundColor: '#ffffff',
                                    color: '#424242',
                                    overflow: 'hidden',
                                }}
                            >
                                <CardContent sx={{ position: 'relative', zIndex: 1 }}>
                                    <Typography
                                        component="div"
                                        sx={{
                                            fontSize: '1.1rem',
                                            fontWeight: 'bold',
                                            color: '#424242',
                                        }}
                                    >
                                        {platform === Platform.ChromeOsExtension ?
                                            'CHROME OS EXT' : platform
                                        }
                                    </Typography>
                                    {devicesCountStatus === RequestStatus.InProgress ? (
                                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                                            <CircularProgress
                                                size={30}
                                                style={{ marginTop: '25px' }}
                                            />
                                        </Box>
                                    ) : (
                                        <>
                                            <Typography variant="h4" sx={{ mt: 1 }}>
                                                <span style={{ color: '#9e9e9e' }}>
                                                    Active:
                                                </span>
                                                <strong style={{ color: '#64a96a', marginLeft: 4 }}>
                                                    {device?.activeCount ?? 0}
                                                </strong>
                                            </Typography>
                                            <Typography variant="h4" sx={{ mb: 1 }}>
                                                <span style={{ color: '#9e9e9e' }}>
                                                    Inactive:
                                                </span>
                                                <strong style={{ color: '#af4c5a', marginLeft: 4 }}>
                                                    {device?.inactiveCount ?? 0}
                                                </strong>
                                            </Typography>
                                        </>
                                    )}
                                </CardContent>
                            </Card>
                        );
                    })
                }
            </section>
            <Grid
                item
                md={4}
                sm={12}
                xs={12}
            >
                <DeviceInformationFilters />
            </Grid>
            <br />
            <Box mt={-1} mb={-1}>
                <TableExporterProvider>
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        padding={'1em 0'}
                        margin={'-2em 0em 0em 0em'}
                    >
                        <Grid
                            item
                            md={4}
                            sm={12}
                            xs={12}
                        >
                            <TableDownloadBar isDeviceInformationPageData={true}/>
                        </Grid>
                        <Box sx={{ mr: 5 }}>
                            <Grid
                                item
                                md={3}
                                sm={12}
                                xs={12}
                            >
                                <AdminSettingSearchFilter/>
                            </Grid>
                        </Box>
                    </Stack>
                    <Grid>
                        <DeviceInformationTable />
                    </Grid>
                </TableExporterProvider>
            </Box>
        </>
    );
}
