export interface DeviceSelectionOptions
{
    agency: number;
    officer: number;
    device: number;
    assignedProfile: never;
}

export interface ProfileSelectionOptions
{
    agency: number;
    assignedProfile: number;
    officer: never;
    device: never;
}
export enum DeviceStatus {
    Active = 'Active / Enabled',
    InActive = 'Inactive',
    Disabled = 'Disabled',
}
export type DeviceSelectionFormOptions = DeviceSelectionOptions | ProfileSelectionOptions;
