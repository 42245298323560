import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { enhancer as storybookAddonEnhancer } from 'addon-redux';

import authorization from './authorization/authorizationSlice';
import captures from './captures/capturesSlice';
import user from './user/userSlice';
import administration from './administration/administrationSlice';
import files from './files/fileSlice';
import comments from './comments/commentsSlice';
import administrationMeta from './administrationMeta/administrationMetaSlice';
export const reducer = {
    authorization,
    captures,
    user,
    administration,
    administrationMeta,
    files,
    comments,
};

export const store = configureStore({
    reducer,
    enhancers: [storybookAddonEnhancer],
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
