import React, { useState, useEffect } from 'react';
import {
    Box,
    Checkbox,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { useTranslation } from 'react-i18next';
import { ToggleSwitch } from 'src/components/toggle-switch/ToggleSwitch';
import { noop } from 'src/utils/common';
import { ServiceConfiguration } from 'src/models/administration/configurationData.model';
import { useConfigurationOptions } from 'src/utils/configurationOptions';
import { userIsInRole } from 'src/utils/roleUtils';
import { Role } from 'src/models/roles.enum';
import { intervalContainerStyle,labelStyle ,rootStyle,toggleContainer} from '../device-service-configurator/DeviceServiceConfigurator';
export interface ServiceConfigurationItem extends ServiceConfiguration {
    label: string;
    override: boolean;
}

export interface ServiceConfiguratorProps {
    configuration: ServiceConfigurationItem;
    onChange?: (item: ServiceConfigurationItem) => void;
    disableConfig: boolean;
}

export function ServiceConfigurator({
    configuration: initialConfiguration,
    onChange = noop,
    disableConfig,
}: Readonly<ServiceConfiguratorProps>): JSX.Element
{
    const { t } = useTranslation();
    const { intervalOptions, syncRateOptions } = useConfigurationOptions();
    const [
        configuration,
        setConfiguration,
    ] = useState<ServiceConfigurationItem>(initialConfiguration);
    const isAdministrator = userIsInRole([Role.Administrator]);

    function onToggleChange(fieldname: keyof ServiceConfigurationItem):
        (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
    {
        return (event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void =>
        {
            const newConf = { ...configuration, [fieldname]: checked };

            setConfiguration(newConf);
            onChange(newConf);
        };
    }
    function onSelectionChange(fieldname: keyof ServiceConfigurationItem):
        (event: SelectChangeEvent<number>, child: React.ReactNode) => void
    {
        return (event: SelectChangeEvent<number>, child: React.ReactNode): void =>
        {
            const selectedValue = Number(event.target.value);
            const newConf = { ...configuration, [fieldname]: selectedValue };

            setConfiguration(newConf);
            onChange(newConf);
        };
    }

    useEffect(() =>
    {
        setConfiguration(initialConfiguration);
    }, [initialConfiguration]);

    return (
        <Stack
            direction="row"
            sx={rootStyle}
        >
            <SettingsIcon fontSize="inherit" sx={{ opacity: configuration.override ? 1 : 0.5 }} />
            <Box sx={labelStyle(configuration.override)}>
                {configuration.label}
            </Box>
            <Box sx={intervalContainerStyle}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                        {t('settings.capture-interval')}
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        size="small"
                        label={t('settings.capture-interval')}
                        value={configuration.captureInterval}
                        onChange={onSelectionChange('captureInterval')}
                        disabled={!configuration.override}
                    >
                        {
                            intervalOptions.map(({ label, seconds }) => (
                                <MenuItem
                                    key={seconds}
                                    value={seconds}
                                >
                                    {label}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Box>
            <Box sx={intervalContainerStyle}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                        {t('settings.sync-interval')}
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        size="small"
                        label={t('settings.sync-interval')}
                        value={configuration.syncInterval}
                        onChange={onSelectionChange('syncInterval')}
                        disabled={!configuration.override}
                    >
                        {
                            intervalOptions.map(({ label, seconds }) => (
                                <MenuItem
                                    key={seconds}
                                    value={seconds}
                                >
                                    {label}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Box>
            {isAdministrator &&
                <Box sx={intervalContainerStyle}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                            {t('settings.sync-rate')}
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            size="small"
                            label={t('settings.sync-rate')}
                            value={configuration.syncRate}
                            onChange={onSelectionChange('syncRate')}
                            disabled={!configuration.override}
                        >
                            {
                                syncRateOptions.map(({ label, limit }) => (
                                    <MenuItem
                                        key={limit}
                                        value={limit}
                                    >
                                        {label}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Box>
            }
            <Box sx={toggleContainer}>
                <ToggleSwitch
                    sx={{ m: 1 }}
                    checked={configuration.enabled}
                    onChange={onToggleChange('enabled')}
                    disabled={!configuration.override}
                />
                <Checkbox
                    checked={configuration.override}
                    onChange={onToggleChange('override')}
                    title={t('settings.override-configuration')}
                />
            </Box>
        </Stack>
    );
}
