import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
    ConfigurationOwner,
    ServiceConfiguration,
} from 'src/models/administration/configurationData.model';
import { noop, sortByKey } from 'src/utils/common';
import {
    ServiceConfigurator,
    ServiceConfigurationItem,
} from '../service-configurator/ServiceConfigurator';
import { Platform } from 'src/models/platforms.enum';
import { DataTypeConfig } from 'src/models/dataType.enum';

export interface ServiceConfigurationPanelProps {
    context?: ConfigurationOwner;
    platform?: Platform;
    ownServices: ServiceConfiguration[];
    fallbackServices: ServiceConfiguration[];
    onChange?: (services: ServiceConfiguration[], isValid: boolean) => void;
    disableConfig: boolean;
}

interface ServiceItemsMap {
    [key: string]: ServiceConfigurationItem;
}

function serviceConfigurationToConfigurationItem(
    conf: ServiceConfiguration,
    override: boolean
): ServiceConfigurationItem
{
    return {
        app: conf.app,
        label: conf.app,
        captureInterval: conf.captureInterval,
        syncInterval: conf.syncInterval,
        syncRate: conf.syncRate,
        enabled: conf.enabled,
        override,
    };
}

function configurationItemToServiceConfiguration({
    app,
    captureInterval,
    syncInterval,
    syncRate,
    enabled,
}: ServiceConfigurationItem): ServiceConfiguration
{
    return {
        app,
        captureInterval,
        syncInterval,
        syncRate,
        enabled,
    };
}

function appConfigurerPropsMapToServiceConfiguration(map: ServiceItemsMap): ServiceConfiguration[]
{
    return Object.values(map)
        .filter((configurationItem) => configurationItem.override)
        .reduce<ServiceConfiguration[]>((acc, configurationItem) => ([
            ...acc,
            configurationItemToServiceConfiguration(configurationItem),
        ]), []);
}

function getConfigurerProps(
    ownedConfiguration: ServiceConfiguration[],
    inheritedConfiguration: ServiceConfiguration[]
): ServiceItemsMap
{

    const inheritedMap = inheritedConfiguration.reduce<Record<string, ServiceConfiguration>>((acc, conf) => {
        acc[conf.app] = conf;
        return acc;
    }, {});

    const propsMap = inheritedConfiguration.reduce<ServiceItemsMap>((acc, conf) =>
    {
        return {
            ...acc,
            [conf.app]: serviceConfigurationToConfigurationItem(conf, false),
        };
    }, {});

    return ownedConfiguration.reduce<ServiceItemsMap>((acc, conf) => {
        
        const syncRate = conf.syncRate ?? inheritedMap[conf.app]?.syncRate;
        const updatedConf = {
            ...conf,
            syncRate,
        };

        return {
            ...acc,
            [updatedConf.app]: serviceConfigurationToConfigurationItem(updatedConf, true),
        };
    }, propsMap);
}

export function ServiceConfigurationPanel({
    context,
    platform,
    ownServices,
    fallbackServices,
    onChange = noop,
    disableConfig,
}: Readonly<ServiceConfigurationPanelProps>): React.ReactElement
{
    const filteredFallBackServices = fallbackServices
        .map((config) =>
        {
            if (config.app === DataTypeConfig.SEARCHES)
            {
                return {
                    ...config,
                    app: DataTypeConfig.SEARCHES_WEBSITES,
                    label: DataTypeConfig.SEARCHES_WEBSITES,
                };
            }
            return config;
        })
        .filter((config) =>
        {
            return config.app !== DataTypeConfig.WEBSITES && config.app !== DataTypeConfig.SEARCHES;
        });
        const filteredOwnServices = ownServices
        .map((config) =>
        {
            if (config.app === DataTypeConfig.SEARCHES)
            {
                return {
                    ...config,
                    app: DataTypeConfig.SEARCHES_WEBSITES,
                };
            }
            return config;
        })
        .filter((config) =>
        {
            return config.app !== DataTypeConfig.WEBSITES && config.app !== DataTypeConfig.SEARCHES;
        });

    const [appConfigurerItemsMap, setAppConfigurerItemsMap] = useState<ServiceItemsMap>(
        getConfigurerProps(ownServices, filteredFallBackServices)
    );
    const [invalidConfs, setInvalidConfs] = useState<string[]>([]);

    function onConfigurationChange(item: ServiceConfigurationItem): void
    {
        const { app, override, captureInterval, syncInterval } = item;
        let newAppItemsMap = { ...appConfigurerItemsMap };

        if (override)
        {
            newAppItemsMap = {
                ...newAppItemsMap,
                [app]: item,
            };
        }
        else
        {
            const fallbackFound = filteredFallBackServices
                .find((service) => service.app === app);

            if (fallbackFound)
            {
                newAppItemsMap = {
                    ...newAppItemsMap,
                    [app]: serviceConfigurationToConfigurationItem(fallbackFound, false),
                };
            }
        }

        setAppConfigurerItemsMap(newAppItemsMap);

        if ((captureInterval && syncInterval) || !override )
        {
            setInvalidConfs((configs) => configs.filter((config) => config !== app));
        }
        else
        {
            setInvalidConfs((configs) => [...configs, app]);
        }

        onChange(
            appConfigurerPropsMapToServiceConfiguration(newAppItemsMap),
            invalidConfs.length === 0
        );
    }

    useEffect(() =>
    {
        setAppConfigurerItemsMap(getConfigurerProps(filteredOwnServices, filteredFallBackServices));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ownServices, fallbackServices]);

    const sortedConfigs = sortByKey(Object.values(appConfigurerItemsMap), 'app');

    const filteredConfigs = sortedConfigs
        .map((config) =>
        {
            if (config.app === DataTypeConfig.SEARCHES)
            {
                return {
                    ...config,
                    app: DataTypeConfig.SEARCHES_WEBSITES,
                    label: DataTypeConfig.SEARCHES_WEBSITES,
                };
            }
            return config;
        })
        .filter((config) =>
        {
            if (context !== ConfigurationOwner.Device
                || (platform !== Platform.Android &&
                platform !== Platform.ChromeOS))
            {
                return config.app !== DataTypeConfig.GEOLOCATION &&
                   config.app !== DataTypeConfig.WEBSITES &&
                   config.app !== DataTypeConfig.SEARCHES;
            }
            return config.app !== DataTypeConfig.WEBSITES &&
               config.app !== DataTypeConfig.SEARCHES;
        });


    return (
        <Grid container rowSpacing={1} columnSpacing={'5%'}>
            {
                filteredConfigs.map((configuration: ServiceConfigurationItem) => (
                    <Grid
                        key={configuration.label}
                        item
                        xs={12}
                    >
                        <ServiceConfigurator
                            configuration={configuration}
                            onChange={onConfigurationChange}
                            disableConfig={disableConfig}

                        />
                    </Grid>
                ))
            }
        </Grid>
    );
}
